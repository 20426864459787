export class SearchNcrmCriteria {
    public accountNumber: string;
    public typeDocument: string;
    public montantMin: string;
    public montantMax: string;
    public dateMax: string;
    public dateMin: string;

    public numValue: string;
    public numRemise: string;
    public codeBanqueTire: string;


    constructor() {}         
}
