<!-------------------------------- View File ------------------------------------>
<app-pdf-viewer
  *ngIf="showFileViewer"
  [pdfSrc]="fileSrc"
  [pdfReference]="fileReference"
  (closeFileViewer)="showFileViewer = false"
></app-pdf-viewer>
<!------------------------------------------------------------------------------->
<div class="init-container">
  <div class="init-decription">
    <img class="bilan-image" src="/assets/images/bilan.png" alt="" />
    <p class="desc1" *ngIf="!showValidationRecap">
      Dans cette rubrique, vous avez la possibilité d’initier un prélèvement en
      important un fichier sous format .txt
    </p>
    <p class="desc2" *ngIf="showValidationRecap">
      Merci de valider votre demande ou de l’annuler si le fichier de
      prélèvement n’est pas conforme
    </p>
  </div>
  <div class="init-upload">
    <div *ngIf="!showValidationRecap" class="init-upload-container">
      <ngx-dropzone
        class="dropzone"
        (change)="onSelect($event)"
        [disabled]="loaderMode"
        [multiple]="false"
        accept="text/plain"
        maxFileSize="1000000"
      >
        <ngx-dropzone-label>
          <img
            *ngIf="loaderMode; else loader_image"
            class="upload-icon"
            src="/assets/images/Ellipsis-2s-80px.gif"
            alt=""
          />
          <ng-template #loader_image
            ><img
              class="upload-icon"
              src="/assets/images/dd-upload-icon.PNG"
              alt=""
          /></ng-template>
          <p class="upload-text1">{{ uploadMessage }}</p>
          <p *ngIf="loaderMode; else loader_upload_text" class="upload-text2">
            {{ file?.name }}
          </p>
          <ng-template #loader_upload_text
            ><p class="upload-text2">
              Le fichier devra être sous format TXT
            </p></ng-template
          >
          <button class="default-btn big-size upload-btn">
            Importer un fichier
          </button>
          <p *ngIf="uploadMessageError" class="init-upload-container-error">
            <span class="icon-warn"><span class="bord">&#9888;</span> | </span>
            Le fichier que vous avez importé n'est pas conforme.
          </p>
        </ngx-dropzone-label>
      </ngx-dropzone>
    </div>
    <div *ngIf="showValidationRecap" class="init-upload-recap">
      <div *ngIf="validateDirectDebitLoader" class="upload-loader-container">
        <img
          class="upload-icon"
          src="/assets/images/Ellipsis-2s-80px.gif"
          alt=""
        />
      </div>
      <table class="recap-table">
        <thead>
          <tr>
            <td colspan="2" class="info">{{ directDebitRecap?.reference }}</td>
            <td>
              <button
                class="default-btn pdf-prev-btn"
                (click)="viewFile(directDebitRecap?.reference + '.txt')"
              >
                Visualiser
              </button>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p class="label">Montant global</p>
              <p class="info">
                {{
                  directDebitRecap?.totalAmount
                    | currency : " " : "" : "" : "fr"
                }}
                MAD
              </p>
            </td>
            <td>
              <p class="label centre">Nbre de prélèvements</p>
              <p class="info centre">
                {{ directDebitRecap?.directDebitNumber }}
              </p>
            </td>
            <td>
              <p class="label">Date d'échéance</p>
              <p class="info">
                {{ directDebitRecap?.dueDate | date : "dd/MM/yyyy" }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p
        *ngIf="1 == 0 && directDebitRecap?.isFilesUploadQuotaAttended"
        class="warningDate"
      >
        <span>&#9888;</span>Votre autre prélèvement du
        {{ today | date : "dd/MM/yyyy" }} a été enregistré. Son traitement sera
        réalisé à une date ultérieure
      </p>
      <div class="validation-action">
        <button
          class="default-btn big-size upload-btn"
          (click)="validateDirectDebit(directDebitRecap?.id)"
        >
          Valider
        </button>
        <button
          class="default-btn big-size init-btn"
          (click)="openPopinConfirmFlag = true"
        >
          Annuler
        </button>
      </div>
    </div>
  </div>
</div>

<!-------------------------------- Error management --------------------------------------->
<app-message-popin
  [openPopinFlag]="openPopinMessageFlag"
  [mode]="popinMode"
  [message]="openPopinMessage"
  [success]="false"
  (popinClosedEvent)="closePopin()"
></app-message-popin>
<!----------------------------------------------------------------------------------------->

<!-------------------------------- Error management --------------------------------------->
<app-confirm-popin
  [openPopinFlag]="openPopinConfirmFlag"
  message="Voulez-vous annuler l’import du fichier?"
  (popinClosedEvent)="closeConfirmPopin($event)"
></app-confirm-popin>
<!-------------------------------- Error management --------------------------------------->

<!-------------------------------- Error management --------------------------------------->
<app-message-popin
  [openPopinFlag]="openPopinSuccessFlag"
  [mode]="popinMode"
  [message]="openPopinMessageSuccess"
  [success]="true"
  (popinClosedEvent)="closeSuccessPopin()"
></app-message-popin>
<!----------------------------------------------------------------------------------------->
