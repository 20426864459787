import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AdvisorExpertDtoList } from '../models/advisorExpertDtoList';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class AdvisorExpertHistoryService {

  size:number=5;
  constructor(private http: HttpClient) {} 
  public findAdvisorExpertHistoryByCodeClient(currentPage:number,codeClient:string): Observable<AdvisorExpertDtoList> {
    return this.http.get<AdvisorExpertDtoList>(
      `${environment.apiUrl}/advisor-expert-history?codeClient=${codeClient}&page=${currentPage}&size=${this.size}`,
      httpOptions
    );
  }

}
