<div class="confirmation-popin withRedBorder">
  <div class="confirmation-wrapper">
    <div *ngIf="signMessages.signatureSuccessMessage != null">
       <div>{{signMessages.signatureSuccessMessage}}</div>
      <button
        type="button"
        class="btn-rounded small-btn btn-gray"
        (click)="onCancel()"
      >
        Ok
      </button>
    </div>

    <div
      *ngIf="signMessages.signatureSuccessMessage == null"
      class="confirmation-content"

      [formGroup]="signForm"
    >
      <div class="confirmation-action" style="width: 100%; text-align: left;">
        <label class="confirmation-text" style="margin-bottom: 20px;">
          <span>
            Le code à entrer dans votre Digipass est :
          </span>
          <span
            style="font-family: source_sans_probold; font-size: 18px; border: 1px solid #909090; padding: 5px; background: #f3f3f3; margin-left: 10px;"
          >{{challengeCode}}</span
          >
          <button
            type="button"
            class="btn-rounded small-btn btn-yellow"
            (click)="onRefresh(null)"
            style="width: 32px; min-width: auto; height: 36px; padding: 0; border-radius: 0;"
          >
            <img
              src="/assets/img/reload.png"
              alt=""
              width="16"
              style="margin-right: 0;"
            />
          </button>
        </label>
        <div>
          <div class="control-group" style="display: flex;">
            <label
              class="control-lbl confirmation-text"
              style="margin-right: 10px; padding-top: 10px;"
            >
              <!-- <span>{{ message }}</span> -->
              <span>Saisissez le code affiché : </span>
            </label>
            <div class="control-field">
              <div style="position: relative; width: 220px;">
                <input
                  type="text"
                  placeholder="******"
                  formControlName="dpResponse"
                  class="simple-field"
                />
              </div>
              <div
                class="is-error"
                *ngIf="
                  !dpResponse.valid && (dpResponse.touched || dpResponse.dirty)
                "
              >
                <span>Champs non valide !</span>
              </div>
              <div
                style="color: red;"
                *ngIf="signMessages.signatureErrorMessage!=null"
              >
                <span>{{signMessages.signatureErrorMessage}}</span>
              </div>
            </div>
          </div>
        </div>
        <div style="text-align: right;">
          <button
            type="button"
            class="btn-rounded small-btn"

            (click)="onSign()"
          >
            Signer
          </button>
          <button
            type="button"
            class="btn-rounded small-btn btn-gray"
            (click)="onCancel()"
          >
            Annuler
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
