<div class="alert">
  <div style="text-align: right;">
    <button
      type="button"
      class="btn btn-close"
      aria-label="Close"
      (click)="closeAlert()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div>
    <button
      type="button"
      class="btn btn-warning"
      (click)="previews(stepNumber)"
      *ngIf="stepNumber >= 0 && stepNumber != 4"
    >
      Retour
    </button>

    <div *ngIf="stepNumber == -1">
      <div>
        <button
          type="button"
          (click)="showHistorique()"
          class="btn btn-light btn-arrow-right "
        >
          Historique des demandes
        </button>
      </div>
      <div>
        <button
          type="button"
          (click)="firstStep()"
          class="btn btn-danger btn-arrow-right "
        >
          + Nouvelle Demande
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="stepNumber >= 0">
    Accès Assistance et Conseil
    <img
      src="/assets/images/adviser_contact.png"
      width="40px"
      alt=""
      *ngIf="stepNumber == 1"
    />
    <br />
  </div>
  <div *ngIf="stepNumber == 0">
    Selectionner qui vous souhaitez contacter :

    <div *ngFor="let manager of managers">
      <input
        type="radio"
        id="manager"
        [value]="manager"
        [(ngModel)]="managerSelect"
        name="manager"
        (change)="selectManager()"
      />{{ manager }}
    </div>
    <div>
      <button
        (click)="nextStep()"
        type="button"
        class="btn btn-danger btn-arrow-right"
      >
        Suivant
      </button>
    </div>
  </div>
  <div *ngIf="stepNumber == 1">
    Name : {{ advisor?.name }} <br />

    N° : {{ advisor?.phoneNumber }} <br />

    Email : {{ advisor?.email }} <br />

    <img [src]="url" [alt]="qrImage?.libelle" />
  </div>
  <div *ngIf="stepNumber == 2">
    <form [formGroup]="form">
      <label for="subject">Sujet</label><br />
      <input
        type="text"
        id="subject"
        name="subject"
        formControlName="subject"
        required
      /><br />
      <label for="message">message</label><br />
      <textarea
        id="message"
        name="message"
        formControlName="message"
        required
        rows="5"
        cols="33"
      >
      </textarea
      ><br />

      <button
        type="button"
        (click)="sendmailAdvisor()"
        class="btn btn-danger btn-arrow-right"
        [disabled]="
          subject.value.trim().length == 0 || message.value.trim().length == 0
        "
      >
        Envoyer la demande
      </button>
    </form>
  </div>
  <div *ngIf="stepNumber == 3">
    <form [formGroup]="formExpert">
      <label for="expert">Contacter un expert SGMA :</label>
      <br />
      <select name="expert" (change)="onChange($event.target.value)">
        <option>List des experts SGMA</option>
        <option *ngFor="let expert of experts" [value]="expert.id">
          {{ expert.type }}
        </option></select
      >

      <br />
      <div
        *ngIf="
          expert != null && (expert.alias != 'CCI' && expert.alias != 'CCM')
        "
      >
        <select name="caf_expert" (change)="onChangeCAF($event.target.value)">
          <option>Ville</option>
          <option *ngFor="let cafExpert of cafExperts" [value]="cafExpert.id">
            {{ cafExpert.caf.libelle }}
          </option></select
        >
        <br />
        <div
          *ngIf="
            expert != nul && cafExpert != null && cafExpert.caf.libelle != null
          "
        >
          Name : {{ advisor?.name }} <br />

          N° : {{ advisor?.phoneNumber }} <br />

          Email : {{ advisor?.email }} <br />

          <img [src]="url" [alt]="qrImage?.libelle" />
        </div>
      </div>
      <div
        *ngIf="
          expert != null && (expert.alias == 'CCI' || expert.alias == 'CCM')
        "
      >
        <label for="subjectExpert">Objet de la demande</label><br />
        <input
          id="subjectExpert"
          type="text"
          name="subjectExpert"
          formControlName="subjectExpert"
          required
        /><br />
        <textarea
          id="messageExpert"
          name="messageExpert"
          formControlName="messageExpert"
          required
          rows="5"
          cols="33"
        >
        </textarea
        ><br />

        <button
          type="button"
          (click)="sendmailExpert()"
          class="btn btn-danger btn-arrow-right"
          [disabled]="
            subjectExpert.value.trim().length == 0 ||
            messageExpert.value.trim().length == 0
          "
        >
          Envoyer le mail
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="stepNumber == 4">
    <div>
      <img src="/assets/images/message_gestionnaire.png" width="80px" alt="" />
      <br />
      {{ succesMessage }} <br />

      <p>
        Un de nos {{ managerLabel }} vous contactera le plus tôt possible pour
        traiter <br />
        votre demande
      </p>

      <div>
        <button
          type="button"
          (click)="showHistorique()"
          class="btn btn-light btn-arrow-right "
        >
          Historique des demandes
        </button>
      </div>
      <div>
        <button
          type="button"
          (click)="firstStep()"
          class="btn btn-danger btn-arrow-right "
        >
          + Nouvelle Demande
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="stepNumber == 5">{{ errorMessage }} <br /></div>

  <div *ngIf="stepNumber == 6">
    <table class="table table-striped table-bordered">
      <thead>
        <tr>
          <th>N° du Ticket</th>
          <th>Date de création</th>
          <th>Titre de demande</th>
          <th>Destinataire</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let history of advisorExpertHistorys">
          <td>{{ history.numTicket }}</td>
          <td>{{ history.creationDate | date: "dd/MM/yyyy" }}</td>
          <td (click)="showDetailAdvisorExpertHistory(history)">
            <span class="underline">{{ history.subject }} </span>
          </td>
          <td>{{ history.recipient }}</td>
          <td *ngIf="history.status === 'EN_COURS'">En Cours</td>
          <td *ngIf="history.status === 'CLOTURER'">Clôturé</td>
        </tr>
      </tbody>
      <app-pagination
        [page]="page"
        (getPage)="getAdvisorExpertHistoryPage()"
      ></app-pagination>
    </table>
  </div>
</div>
