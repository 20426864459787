import { Component, OnInit } from '@angular/core';
import { Client } from 'src/app/models/client';
import { ClientSharedService } from 'src/app/service/client-shared.service';
import { BeneficiaryListDto } from '../../../../models/BeneficiaryListDto';
import { Page } from '../../../../models/page';
import { BeneficiaryService } from '../../../../service/beneficiary.service';

@Component({
  selector: 'app-signed',
  templateUrl: './beneficiaries-signed.component.html',
  styleUrls: ['./beneficiaries-signed.component.scss'],
})
export class BeneficiariesSignedComponent implements OnInit {
  public beneficiaryDtoList: BeneficiaryListDto;
  public technicalError: boolean;
  public client: Client;
  page = new Page();

  constructor(
    private beneficiaryService: BeneficiaryService,
    private clientSharedService: ClientSharedService
  ) {
    this.page.init();
  }

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.beneficiaryService
      .findAllSignedBeneficiaries('354', 0)
      .toPromise()
      .then((result) => (this.beneficiaryDtoList = result))
      .catch(() => (this.technicalError = true));
  }

  getBeneficiariesCurrentPage(): void {
    this.beneficiaryService
      .findAllToSignBeneficiaries('354', this.page.currentPage)
      .toPromise()
      .then((result) => {
        this.beneficiaryDtoList = result;
        this.page.totalPages = this.beneficiaryDtoList.totalPages;
        this.page.totalElements = this.beneficiaryDtoList.totalElements;
      });
  }
}
