import { Component, OnInit } from '@angular/core';
import {SoftTokenClientsService} from 'src/app/service/soft-token-clients.service';
import {ClientService} from 'src/app/service/client.service';
import { SoftClientList } from 'src/app/models/soft-client-list';
@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit {

  softToken:Boolean= false;
  softClientList:SoftClientList;
  constructor(    private clientService: ClientService,
    private SoftTokenClientsService:SoftTokenClientsService) {
}

  ngOnInit() {
    this.clientService.getClients()
    .subscribe((res) => {
    let  customersNumber  =  res.map(result => result.customerNumber);
    this.softClientList=new SoftClientList();
    this.softClientList.customerNumbers=customersNumber;
    this.SoftTokenClientsService.checkSoftTokenClients(this.softClientList).subscribe(soft => this.softToken=soft)
    });


  }


}
