import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SignatureChallengeAndDresponse } from '../../../models/signatureChallengeAndDresponse';
import { ChallengeService } from '../../../service/challenge.service';
import { SignMessages } from './signMessages';

@Component({
  selector: 'app-sign-dialog',
  templateUrl: './dialog-sign.component.html',
  styleUrls: ['./dialog-sign.component.scss'],
})
export class DialogSignComponent implements OnInit {
  @Input() validatorCilcked;
  @Output() sign = new EventEmitter<SignatureChallengeAndDresponse>();
  @Output() cancelled = new EventEmitter<string>();
  @Input() displayConfirmDialog = false;
  signForm: FormGroup;
  challengeCode;
  challengeKey;
  @Input() signMessages: SignMessages;

  constructor(
    private fb: FormBuilder,
    private challengeService: ChallengeService
  ) {}

  ngOnInit(): void {
    this.signForm = this.fb.group({
      dpResponse: this.fb.control(null, [
        Validators.required,
        Validators.maxLength(8),
      ]),
    });

    this.challengeService
      .getChallenge()
      .toPromise()
      .then((result) => {
        this.challengeCode = result.challenge;
        this.challengeKey = result.challengeKey;
      });
  }

  get dpResponse(): FormControl {
    return this.signForm.get('dpResponse') as FormControl;
  }

  onSign(): void {
    this.sign.emit(
      new SignatureChallengeAndDresponse(
        this.challengeKey,
        this.dpResponse.value
      )
    );
  }

  onCancel(): void {
    this.resetForm();
    this.cancelled.emit();
  }

  resetForm(): void {
    this.signForm.reset();
  }

  onRefresh(event: string): void {
    // this.loadCallenge();
  }

  formValid(): boolean {
    return this.signForm.valid;
  }
}
