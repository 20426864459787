import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { GedDocument } from 'src/app/models/gedDocument';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private http: HttpClient) {}

  download(
    id: string,
    baseName: string,
    familyCode: string,
    customerNumber: string
  ): Observable<any> {
    if (familyCode == 'IMG_VALEUR') {
      return this.dowloadImage(id);
    } else {
      return this.downlaodDoc(id, baseName, customerNumber);
    }
  }

  dowloadImage(id: string): any {
    let response = {};
    return this.http
      .post<any>(`/portal-customer/ged/ncrm/get-images`, {
        documentsIDS: id,
      })
      .subscribe((res) => {
        response['doc1'] = res.resultgetimages.images[0].frontImage;
        response['doc2'] = res.resultgetimages.images[0].rearImage;
      });
  }

  downlaodDoc(id: string, baseName: string, customerNumber: string): any {
    let response = {};
    this.http
      .post<GedDocument>(
        `/portal-customer/ged/get-document/${customerNumber}`,
        {
          idDocument: id,
          baseName: baseName,
        }
      )
      .subscribe((res) => {
        response['doc1'] = res.document;
        response['doc2'] = null;
      });
    return response;
  }

  convertBase64ToBlobData(
    doc1: string,
    contentType: string,
    sliceSize: number
  ) {
    const byteCharacters = atob(doc1);
    const byteArrays1 = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays1.push(byteArray);
    }

    const blob = new Blob(byteArrays1, { type: contentType });

    return blob;
  }
}
