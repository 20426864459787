export abstract class Util {
  static slides = [
    {
      title: 'Business Forum EBERD ',
      thumbnail: 'business-eburd.png',
      details:
        'Société Générale Maroc partenaire du "Business Forum EBRD", qui se tient du 10 au 12 mai 2022. ',
      moreDetails:
        'Société Générale Maroc partenaire du "Business Forum EBRD", qui se tient du 10 au 12 mai 2022. Cette participation n’est qu’une action parmi d’autres, confirmant l’engagement de Société Générale Maroc auprès de ses clients entreprises, dans leur démarche de décarbonation. Des offres de financement durable sont disponibles pour accompagner vos projets de transition énergétique, parlez-en à votre conseiller clientèle.</p>',
    },
    {
      title: 'Service VIP pour vos cartes entreprises',
      thumbnail: 'service-vip.png',
      details:
        'Société Générale Maroc met à la disposition des porteurs de ses cartes bancaires entreprises, le Service Assistance VIP 24h/7J, qui apporte toute l’assistance nécessaire pour les cas suivants au Maroc, comme à l’étranger',
      moreDetails:
        '<p>Société Générale Maroc met à la disposition des porteurs de ses cartes bancaires entreprises, le Service Assistance VIP 24h/7J, qui apporte toute l’assistance nécessaire pour les cas suivants au Maroc, comme à l’étranger</p><ul><li>Carte bloquée après plusieurs tentatives d’insertion du code confidentiel ou de CVV erronées.</li><li>Carte bancaire est non-active sur le système </li><li>Non aboutissement des opérations par carte.</li></ul><p>Service joignable au 0520 31 51 51 <strong>(Au Maroc)</strong> et au 00212 520 31 51 51 <strong>(A l’étranger)</strong></p>',
    },
    {
      title: 'SG Social Impact Fund ',
      thumbnail: 'social-fund.png',
      details: 'Une innovation financière au service de la solidarité',
      moreDetails:
        '<p>Société Générale Maroc, à travers Sogecapital Gestion, lance SG Social Impact Fund, un fonds OPCVM à très faible risque, dont les revenus générés sont distribués intégralement aux associations, ayant des projets à vocation sociale, pour venir en aide aux personnes en difficulté.Ce fonds solidaire peut être la traduction d’une de vos actions RSE en entreprise.Pour plus d’information, contactez votre conseiller clientèle habituel. </p>',
    },
    {
      title:
        'ONEBANK, vos opérations bancaires, où vous souhaitez et là où vous êtes ! ',
      thumbnail: 'news03.jpg',
      moreDetails:
        '<p>Grâce au nouveau dispositif One Bank On Client, Société Générale vous donne la possibilité de passer vos transactions bancaires, que vous ne pouvez pas réaliser via nos solutions de banque à distance, dans toutes les agences et/ ou centre d’affaires.</p>',
      details:
        'Grâce au nouveau dispositif One Bank On Client, Société Générale vous donne la possibilité de passer vos transactions bancaires, que vous ne pouvez pas réaliser via nos solutions de banque à distance, dans toutes les agences et/ ou centre d’affaires.',
    },
    {
      title: 'Sogef@ctoring',
      thumbnail: 'news06.jpg',
      moreDetails:
        '<p>La solution globale pour la gestion de vos factures en ligne, accessible depuis votre ordinateur, smartphone et tablette. Sogef@ctoring, permet de gérer vos dossiers d’affacturage à distance, en vous offrant une panoplie de fonctionnalités, comme, la consultation de vos états de factures, des reportings de vos règlements, et la saisie de vos factures et demandes de financement. Pour plus d’information, veuillez contacter votre conseiller en agence ou centre d’affaires, ou un expert Sogefactoring au contact.sogefactoring@socgen.com</p>',
      details:
        'La solution globale pour la gestion de vos factures en ligne, accessible depuis votre ordinateur, smartphone et tablette. Sogef@ctoring, permet de gérer vos dossiers d’affacturage à distance, en vous offrant une panoplie de fonctionnalités, comme, la consultation de vos états de factures, des reportings de vos règlements, et la saisie de vos factures et demandes de financement. Pour plus d’information, veuillez contacter votre conseiller en agence ou centre d’affaires, ou un expert Sogefactoring au contact.sogefactoring@socgen.com',
    },
  ];

  static replacePhone(
    phoneNumber: string,
    lastCharacter: number,
    replaceCharacter: string
  ): string {
    return (
      this.remplirPhone(phoneNumber.length - lastCharacter, replaceCharacter) +
      phoneNumber.substring(phoneNumber.length - lastCharacter)
    );
  }

  static remplirPhone(nombre: number, replaceCharacter: string): string {
    let valuer = '';
    for (let i = 0; i < nombre; i++) {
      valuer += replaceCharacter;
    }
    return valuer;
  }

  // Gestion des cookies.
  static getCookie(name: string) {
    let cookies: Array<string> = document.cookie.split(';');
    let cookiesLength: number = cookies.length;
    let cookieName = `${name}=`;
    let cn: string;

    for (let i: number = 0; i < cookiesLength; i += 1) {
      cn = cookies[i].replace(/^\s+/g, '');
      if (cn.indexOf(cookieName) == 0) {
        return cn.substring(cookieName.length, cn.length);
      }
    }
    return '';
  }

  static deleteCookie(name) {
    this.setCookie(name, '', -1);
  }

  static setCookie(
    name: string,
    value: string,
    expireDays: number,
    path: string = ''
  ) {
    let date: Date = new Date();
    date.setTime(date.getTime() + expireDays * 86400 * 1000);
    let _expire: string = `expires=${date.toUTCString()}`;
    let _path: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${_expire}${_path}`;
  }
}
