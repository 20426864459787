<div class="main-popin-container show" *ngIf="openPopinFlag">
  <div class="popin-content-body small-popin">
    <button *ngIf="mode !='loader'" type="button" class="close-btn" (click)="closeTab()">
      x
    </button>
    <div class="popin-container">
      <div *ngIf="mode=='loader'; else message_template">
        <img class="popin-image" src="/assets/images/Ellipsis-2s-80px.gif" alt="">
      </div>
      <ng-template #message_template>
        <img *ngIf="success; else error_template" class="popin-image" src="/assets/images/message-ok.png" alt="">
        <ng-template #error_template><img class="popin-image" src="/assets/images/message-ko.png" alt=""></ng-template>
      </ng-template>
      
      <div class="message">
          <p>{{message}}</p>
      </div>
    </div>
  </div>
</div>