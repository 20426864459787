export class SearchNoticeCriteria {
    public account: string;
    public documentType: string;
    public montantMin: string;
    public montantMax: string;
    public devise: string;
    public startDate: string;
    public endDate: string;


    constructor() {}
}
