export enum DirectDebitSignatureStatus {
  PENDING = 'PENDING', //"WAITING FOR SIGNATURE"
  IN_PROGRESS = 'IN_PROGRESS', //("WAITING FOR CONTRE SIGNATURE"),
  COMPLETED = 'COMPLETED', //("SIGNED"),
  WAITING_EXECUTION = 'WAITING_EXECUTION', //("TAKEN CARE OF"),
  WAITING_VALIDATION = 'WAITING_VALIDATION', // 'WAITING FOR VALIDATION',
  WAITING_REJECTION = 'WAITING_REJECTION', //'WAITING FOR REJECTION',
  EXECUTING = 'EXECUTING', //("EXECUTION IN PROGRESS"),
  EXECUTED = 'EXECUTED', //("EXECUTED"),
  PAID = 'PAID', //("EXECUTED AND THE SORT IS PAID"),
  UNPAID = 'UNPAID', //("EXECUTED AND THE SORT IS UNPAID"),
  CANCELED = 'CANCELED', //("CANCELED BY THE CLIENT")?
  REJECTED = 'REJECTED', //("REJECTED");
}

export enum DirectDebitSignatureMappedStatus {
  PENDING = 'PENDING', //"WAITING FOR SIGNATURE"
  IN_PROGRESS = 'IN_PROGRESS', //("WAITING FOR CONTRE SIGNATURE"),
  EXECUTING = 'EXECUTING', //("EXECUTION IN PROGRESS"),
  EXECUTED = 'EXECUTED', //("EXECUTED"),
  CANCELED = 'CANCELED', //("CANCELED BY THE CLIENT")?
  REJECTED = 'REJECTED', //("REJECTED");
}

export function mappingDirectDebitSignatureStatus(
  stat: DirectDebitSignatureStatus
): string {
  switch (stat) {
    case DirectDebitSignatureStatus.PENDING:
      return 'En attente de signature';
    case DirectDebitSignatureStatus.IN_PROGRESS:
      return 'En cours de signature';
    case DirectDebitSignatureStatus.COMPLETED:
      return "En cours d'exécution";
    case DirectDebitSignatureStatus.WAITING_EXECUTION:
      return "En cours d'exécution";
    case DirectDebitSignatureStatus.WAITING_VALIDATION:
      return "En cours d'exécution";
    case DirectDebitSignatureStatus.WAITING_REJECTION:
      return "En cours d'exécution";
    case DirectDebitSignatureStatus.EXECUTING:
      return "En cours d'exécution";
    case DirectDebitSignatureStatus.EXECUTED:
      return 'Exécuté';
    case DirectDebitSignatureStatus.PAID:
      return 'Exécuté';
    case DirectDebitSignatureStatus.UNPAID:
      return 'Exécuté';
    case DirectDebitSignatureStatus.CANCELED:
      return 'Annulé';
    case DirectDebitSignatureStatus.REJECTED:
      return 'Rejeté';
  }
}

export function getMappedDirectDebitSignatureStatus(
  stat: DirectDebitSignatureStatus
): DirectDebitSignatureMappedStatus {
  switch (stat) {
    case DirectDebitSignatureStatus.PENDING:
      return DirectDebitSignatureMappedStatus.PENDING;
    case DirectDebitSignatureStatus.IN_PROGRESS:
      return DirectDebitSignatureMappedStatus.IN_PROGRESS;
    case DirectDebitSignatureStatus.COMPLETED:
    case DirectDebitSignatureStatus.WAITING_EXECUTION:
    case DirectDebitSignatureStatus.WAITING_VALIDATION:
    case DirectDebitSignatureStatus.WAITING_REJECTION:
    case DirectDebitSignatureStatus.EXECUTING:
      return DirectDebitSignatureMappedStatus.EXECUTING;
    case DirectDebitSignatureStatus.EXECUTED:
    case DirectDebitSignatureStatus.PAID:
    case DirectDebitSignatureStatus.UNPAID:
      return DirectDebitSignatureMappedStatus.EXECUTED;
    case DirectDebitSignatureStatus.CANCELED:
      return DirectDebitSignatureMappedStatus.CANCELED;
    case DirectDebitSignatureStatus.REJECTED:
      return DirectDebitSignatureMappedStatus.REJECTED;
  }
}
