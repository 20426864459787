<aside class="right-sidebar" id="rightSideBar">
  <div class="header-container">
    <div class="header">
      <h2 class="title-heading01">accès à mes experts</h2>
      <button class="btn-close" (click)="closeSideBar()">&times;</button>
    </div>

    <div class="tabs-container">
      <mat-tab-group
        [(selectedIndex)]="selectedIndex"
        (selectedTabChange)="refresh()"
      >
        <mat-tab label="Nouvelle demande" id="2">
          <div class="content-container">
            <div class="contact-expert" *ngIf="stepNumberExpert < 5">
              <h2 class="title-heading01">Contacter un expert</h2>
              <img
                src="/assets/images/contact-expert.svg"
                alt="contact expert sgma"
              />
            </div>

            <div>
              <div class="expert-items" *ngIf="stepNumberExpert == 0">
                <div
                  class="expert-item"
                  *ngFor="let expert of experts; let i = index"
                  (click)="selectExpert(expert, i)"
                >
                  <p>{{ expert.type }}</p>
                  <div class="app-fake-link">
                    <img src="assets/images/ic_arrow_right.svg" />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="expert-content"
              *ngIf="stepNumberExpert > 0 && stepNumberExpert < 5"
            >
              <div class="expert-header">
                <div class="ic_back" (click)="back()">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="9"
                    fill="none"
                  >
                    <path
                      stroke="#E9041E"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 4.742H1M4.744 8.486 1 4.742M4.744 1 1 4.744"
                    />
                  </svg>
                </div>
                <span class="title-black">{{ expertSelectionner.type }}</span>

                &nbsp;
              </div>

              <div class="expert-body">
                <!-- toggle "correspand" and "conseil" blocks -->
                <div class="correspand">
                  <!-- toggle "first" and "second" blocks -->
                  <div class="first" *ngIf="stepNumberExpert == 1">
                    <p class="title-gray">
                      Veuillez sélectionner la ville et votre correspondant :
                    </p>
                    <div class="city-selector">
                      <mat-form-field class="mat-control w50">
                        <mat-label>Ville</mat-label>
                        <input  (keyup)="filterListExpert($event.target.value)"
                          type="text"
                          placeholder="Ville"
                          aria-label="Ville"
                          matInput
                          [formControl]="myControl"
                          [matAutocomplete]="auto"
                        />
                        <mat-autocomplete
                          #auto="matAutocomplete"
                          [displayWith]="getCity.bind(this)"
                        >
                          <mat-option
                            *ngFor="let option of cafExperts"
                            [value]="option?.id"
                            (onSelectionChange)="onChangeCAF(option?.id)"
                          >
                            {{ option?.cafLibelle }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>

                      <button
                        type="button"
                        class="btn-icon-new next active"
                        [disabled]="suivant"
                        (click)="lastStepExpert()"
                      >
                        <span>SUIVANT</span>
                      </button>
                    </div>
                  </div>
                  <div class="second" *ngIf="stepNumberExpert == 3">
                    <div class="contact-items">
                      <p class="contact user">{{ advisor?.name }}</p>
                      <p class="contact phone">{{ advisor?.phoneNumber }}</p>
                      <p class="contact mail">{{ advisor?.email }}</p>
                    </div>

                    <div class="qr-content">
                      <p>
                        Nos conseillers sont joignables du lundi au vendredi de
                        9h à 16h30.
                        <br />
                        Vous pouvez scanner le Qr code pour enregistrer
                        directement les informations de votre correspondant.
                      </p>
                      <img [src]="url" [alt]="qrImage?.libelle" />
                    </div>
                  </div>
                </div>

                <div
                  class="conseil"
                  *ngIf="
                    stepNumberExpert == 4 &&
                    expert != null &&
                    (expert.alias == 'CCI' || expert.alias == 'CCM')
                  "
                >
                  <form [formGroup]="formExpert">
                    <mat-form-field class="mat-control">
                      <mat-label>Objet de la demande</mat-label>
                      <input matInput formControlName="subjectExpert" />
                    </mat-form-field>

                    <mat-form-field class="mat-control">
                      <mat-label>Message</mat-label>
                      <textarea
                        matInput
                        formControlName="messageExpert"
                      ></textarea>
                    </mat-form-field>

                    <button
                      type="button"
                      class="btn-icon-new send active"
                      [disabled]="
                        subjectExpert.value.trim().length == 0 ||
                        messageExpert.value.trim().length == 0
                      "
                      (click)="sendmailExpert()"
                    >
                      <span>ENVOYER LE MESSAGE</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>

            <!-- in case message success and error -->
            <div class="msg-info">
              <div class="msg-info" *ngIf="stepNumberExpert == 5">
                <img src="/assets/images/mg-sent-success.svg" alt="success" />
                <!-- in case error -->
                <!-- <img src="/assets/images/msg-error.svg" alt="error" /> -->
                <p class="p1">
                  Votre message a été envoyé avec succès !!
                  <!-- in case error -->
                  <!-- Désolé, votre message n’a pas pu  être envoyé !! -->
                </p>
                <p class="p2">
                  Un de nos conseillers vous contactera le plus tôt possible
                  pour traiter votre demande.
                  <!-- in case error -->
                  <!-- Veuillez ressayer plus tard. -->
                </p>
                <button class="cta" (click)="nextStep1()()">
                  Historique des demandes
                </button>
                <button class="cta active" (click)="sendRequest()">
                  Nouvelle demande
                </button>
              </div>
              <div class="msg-info" *ngIf="stepNumberExpert == 6">
                <img src="/assets/images/msg-error.svg" alt="error" />
                <p class="p1">
                  Désolé, votre message n’a pas pu être envoyé !!
                </p>
                <p class="p2">
                  Veuillez ressayer plus tard
                </p>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Historique des demandes" id="1">
          <div class="content-container">
            <!-- in case no rsult found show this block -->

            <div class="msg-info" *ngIf="advisorExpertHistorys.length == 0">
              <img src="/assets/images/no_history.svg" alt="no history" />
              <p class="p1">
                Votre historique de demandes est actuellement vide !!
              </p>
              <p class="p2">Souhaitez-vous contacter un expert ?</p>
              <button class="cta" (click)="previousStep()">
                OUI, EFFETUER UNE DEMANDE
              </button>
            </div>
            <div>
              <div
                class="history-container"
                *ngFor="let history of advisorExpertHistorys; let i = index"
              >
                <div class="first">
                  <div class="label-value">
                    <p class="label">Référence de la demande</p>
                    <p class="value bold">{{ history.numTicket }}</p>
                  </div>
                  <div class="label-value">
                    <p class="label">Date de création</p>
                    <p class="value">
                      {{ history.creationDate | date: "dd/MM/yyyy" }}
                    </p>
                  </div>
                  <div class="label-value fixedWidth">
                    <p class="label">Destinataire</p>
                    <p class="value bold">{{ history.recipient }}</p>
                  </div>
                </div>
                <div class="second">
                  <div class="second-summary">
                    <div class="label-value">
                      <p class="label">Objet de demande</p>
                      <p class="value red">{{ history.subject }}</p>
                    </div>
                    <div class="ctas fixedWidth">
                      <!-- either add class "pending" or "success" -->
                      <div
                        class="tag pending"
                        *ngIf="history.status === 'EN_COURS'"
                      >
                        <span>En attente</span>
                      </div>
                      <div
                        class="tag success "
                        *ngIf="history.status === 'CLOTURER'"
                      >
                        <span>Clôturé</span>
                      </div>
                      <!-- add class "active" if "toggle()" aka "isOpen" is active -->
                      <button class="btn-details" (click)="toggle(i)">
                        <span>Détail</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="none"
                        >
                          <path
                            fill="#4A555F"
                            fill-opacity=".8"
                            d="M8 3C4.667 3 1.82 5.073.667 8c1.153 2.927 4 5 7.333 5 .24 0 .48 0 .72-.033a4.06 4.06 0 0 1-.053-.634c0-.24.027-.48.067-.72-.24.027-.487.054-.734.054A6.547 6.547 0 0 1 2.12 8a6.547 6.547 0 0 1 11.76 0c-.08.16-.173.3-.26.453.44.107.86.287 1.24.547.18-.333.34-.667.474-1-1.154-2.927-4-5-7.334-5Zm0 3a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm4 3.667v2h-2V13h2v2h1.334v-2h2v-1.333h-2v-2H12Z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div
                    class="details-container"
                    [@openClose]="isOpen === i ? 'open' : 'close'"
                  >
                    <p class="title">Contenu du message</p>
                    <div class="msg">
                      {{ history.message }}
                    </div>
                  </div>
                </div>
              </div>
              <app-pagination
                [page]="page"
                (getPage)="getAdvisorExpertHistoryPage()"
              ></app-pagination>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</aside>
