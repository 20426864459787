import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GetFileResponseDto} from '../../models/getFileResponseDto';

@Component({
  selector: 'app-transfer-widget',
  templateUrl: './transfer-widget.component.html',
  styleUrls: ['./transfer-widget.component.scss']
})
export class TransferWidgetComponent implements OnInit {

  @Input() transfer: GetFileResponseDto;
  @Input() userCanSign;
  @Output() startSignature = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit(): void {
  }

  startSigningOrder(id): void {
    this.startSignature.emit(id);
  }
}
