<ul class="custom-pagination-container" *ngIf="page.totalPages > 1">
  <li>
    <button
      class="pagination-btn prev"
      type="button"
      (click)="page.currentPage === 0 ? '' : onPrevious()"
      [disabled]="page.currentPage === 0"
    >
      <img src="/assets/images/ic_arrow_next.svg" alt="next" />
    </button>
  </li>
  <li>
    <button type="button" class="pagination-btn current-page">
      {{ page.currentPage + 1 }}
    </button>
  </li>
  <li [class.hidden]="page.totalPages == page.currentPage + 1">
    <button class="pagination-btn" type="button" (click)="onNext()">
      {{ page.currentPage + 2 }}
    </button>
  </li>
  <li>
    <button
      type="button"
      (click)="page.totalPages < 2 ? '' : onNext()"
      class="pagination-btn"
      [disabled]="page.totalPages < 2"
    >
      <img src="/assets/images/ic_arrow_next.svg" alt="next" />
    </button>
  </li>
</ul>
