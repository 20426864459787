import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserConfig } from '../models/userConfig';

import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};
@Injectable({
  providedIn: 'root',
})
export class UserConfigService {
  constructor(private http: HttpClient) {}

  public findUserConfigByUser(username: string): Observable<UserConfig> {
    return this.http.get<UserConfig>(
      `${environment.apiUrl}/user-config?username=${username}`,
      httpOptions
    );
  }
  public hideSoftTokenPopup(username: string): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/user-config/hide-soft-token-popup?username=${username}`, httpOptions);
  }

  public numberOfSoftToken(username: string): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/user-config/number-of-soft-token?username=${username}`, httpOptions);
  }
  public findMaxOfnumberOfSoftToken(): Observable<string> {
    return this.http.get<string>(
      `${environment.apiUrl}/user-config/number-max-of-soft-token`,
      httpOptions
    );
  }

 
}
