import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Advisor } from '../models/advisor';
import { QrImage } from '../models/qrImage';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class QrImageService {
  constructor(private http: HttpClient) {}

  public generateQR(advisor: Advisor,widht:number,height:number): Observable<QrImage> {
    return this.http.post<QrImage>(
      `${environment.apiUrl}/qrImage/generateQrCode/?widht=${widht}&height=${height}`,advisor,
      httpOptions
    );
  }

}
