<div class="close-container">
  <button
    type="button"
    class="btn btn-close"
    aria-label="Close"
    (click)="onNoClick()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="alert alert-success alert-dismissible" *ngIf="message">
  <strong>Success!</strong> un mail a été envoyé pour le soft token
  <a
    *ngIf="message"
    href="#"
    class="close"
    data-dismiss="alert"
    aria-label="close"
    >&times;</a
  >
</div>
<div class="alert alert-danger alert-dismissible" *ngIf="errors">
  <strong>Alert !</strong> Soft Token non envoyer
  <a
    *ngIf="errors"
    href="#"
    class="close"
    data-dismiss="alert"
    aria-label="close"
    >&times;</a
  >
</div>

<div class="modal-header">
  <img
    src="/assets/images/soft_token.png"
    alt="Soft Token"
    class="center"
  /><br />
</div>
<div class="modal-body">
  <h4>
    Avec My Business Pass Mobile, vous n’aurez plus besoin de calculette, connexion facile
    !
  </h4>

 <p>
  Vous n’aurez plus besoin de vous encombrer d’un Digipass physique. 
  Découvrez comment vous authentifier et effectuer vos opérations en ligne.
  
  </p>
</div>

<div class="modal-footer">
  <div class="cta-container">
    <button
      (click)="activerSoftToken()"
      type="button"
      class="btn btn-danger btn-arrow-right"
    >
  Activer My Business Pass Mobile
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="12" fill="none">
        <path
          stroke="#EE5B6F"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M1 4.5h9M6.256 8.244 10 4.5M6.256.758 10 4.502"
        />
      </svg>
    </button>
    

  </div>
  <form [formGroup]="form" >
  <div class="check-container">
    <input type="checkbox"  formControlName="hideDialog" />
    <span>Ne plus afficher cette annonce</span>
  </div>
  </form>


</div>
