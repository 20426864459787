import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { SoftTokenActivation } from '../models/softTokenActivation';
import { User } from '../models/user';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};
@Injectable({
  providedIn: 'root',
})
export class SoftTokenActivationService {
  constructor(private http: HttpClient) {}

  public hasSoftToken(): Observable<SoftTokenActivation> {
    return this.http.get<SoftTokenActivation>(
      `${environment.apiUrl}/users/soft-token`,
      httpOptions
    );
  }

  public getSoftToken(): Observable<Boolean> {
    return this.http.get<Boolean>(
      `${environment.apiUrl}/users/soft-token/has-soft-token`,
      httpOptions
    );
  }

  public activerSoftToken(user: User): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/users/soft-token/activate`,
      user,
      httpOptions
    );
  }
}
