<div class="video-container">


  <video controls (click)="toggleVideo()" #videoPlayer>
		<source src="/assets/videos/171215_B_22.mp4"  />
		Browser not supported
	</video>

  <div class="text-container">
    <p>
      Avec Soft Token,vous n'aurez plus besoins de calculette.<br>   Avec Soft Token,vous n'aurez plus besoins de calculette,<br>   Avec Soft Token,vous n'aurez plus besoins de calculette  Avec Soft Token,vous n'aurez plus besoins de calculette
    </p>
   <div>
</div>
