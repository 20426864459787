import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-soft-token-details',
  templateUrl: './soft-token-details.component.html',
  styleUrls: ['./soft-token-details.component.scss'],
})
export class SoftTokenDetailsComponent implements OnInit {
  @ViewChild('videoPlayer', {}) videoplayer: ElementRef;
  constructor() {}

  ngOnInit() {}
  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }
}
