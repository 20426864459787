<!-------------------------------- View File ------------------------------------>
<app-pdf-viewer
  *ngIf="showFileViewer"
  [pdfSrc]="fileSrc"
  [pdfReference]="fileReference"
  (closeFileViewer)="showFileViewer = false"
></app-pdf-viewer>
<!------------------------------------------------------------------------------->
<div class="body-wrapper">
  <div *ngIf="noFilesFound; then noResultFound; else filesProcess"></div>
  <ng-template #filesProcess>
    <div class="files-container">
      <img
        *ngIf="loading || isPendingRefreshChalCodBtn"
        class="upload-icon"
        src="/assets/images/Ellipsis-2s-80px.gif"
        alt=""
      />
      <div class="file-tab" *ngFor="let file of files">
        <div class="file-info">
          <div class="file-elm file-reference">
            <p class="label">Référence du prélèvement</p>
            <p class="info">{{ file.reference }}</p>
          </div>
          <div class="file-elm">
            <p class="label">Date d'échéance</p>
            <p class="info">{{ file.dueDate | date : "dd/MM/yyyy" }}</p>
          </div>
          <div class="file-elm">
            <p class="label">Montant global</p>
            <p class="info">
              {{ file.totalAmount | currency : " " : "" : "" : "fr" }}
              <span>MAD</span>
            </p>
          </div>
          <div class="file-elm">
            <p class="label">Nbre de prélèvements</p>
            <p class="info">{{ file.debitNumber }}</p>
          </div>
          <div class="file-elm">
            <p class="label">Source</p>
            <p class="info source" *ngIf="file.origin == 'UPLOAD'">
              <img
                src="../../../../assets/images/ic_source_import.svg"
                alt="'menu-icon"
              />
              Import
            </p>
            <p class="info source" *ngIf="file.origin == 'CFT'">
              <img
                src="../../../../assets/images/ic_source_cft.svg"
                alt="'menu-icon"
              />
              CFT
            </p>
          </div>
          <div class="file-elm">
            <p class="label">Statut</p>

            <!--En attente de signature-->
            <p
              class="info statut pending"
              *ngIf="
                getMappedDirectDebitSignatureStatus(file.signatureStatus) ==
                directDebitSignatureMappedStatus.PENDING
              "
            >
              <img
                src="../../../../assets/images/ic_pending.svg"
                alt="'menu-icon"
              />
              {{ mappingDirectDebitSignatureStatus(file.signatureStatus) }}
            </p>

            <!--En cours de signature-->
            <p
              class="info statut in-progress"
              *ngIf="
                getMappedDirectDebitSignatureStatus(file.signatureStatus) ==
                directDebitSignatureMappedStatus.IN_PROGRESS
              "
            >
              <img
                src="../../../../assets/images/ic_pending.svg"
                alt="'menu-icon"
              />
              {{ mappingDirectDebitSignatureStatus(file.signatureStatus) }}
            </p>

            <!--En cours d'éxécution-->
            <p
              class="info statut executing"
              *ngIf="
                getMappedDirectDebitSignatureStatus(file.signatureStatus) ==
                directDebitSignatureMappedStatus.EXECUTING
              "
            >
              <img
                src="../../../../assets/images/ic_pending.svg"
                alt="'menu-icon"
              />
              {{ mappingDirectDebitSignatureStatus(file.signatureStatus) }}
            </p>

            <!--Exécuté-->
            <p
              class="info statut execute"
              *ngIf="
                getMappedDirectDebitSignatureStatus(file.signatureStatus) ==
                directDebitSignatureMappedStatus.EXECUTED
              "
            >
              <img
                src="../../../../assets/images/ic_multi_check.svg"
                alt="'menu-icon"
              />
              {{ mappingDirectDebitSignatureStatus(file.signatureStatus) }}
            </p>

            <!--Annulé-->
            <p
              class="info statut annuler"
              *ngIf="
                getMappedDirectDebitSignatureStatus(file.signatureStatus) ==
                directDebitSignatureMappedStatus.CANCELED
              "
            >
              <img
                src="../../../../assets/images/ic_annuler.svg"
                alt="'menu-icon"
              />
              {{ mappingDirectDebitSignatureStatus(file.signatureStatus) }}
            </p>

            <!--Rejeté-->
            <p
              class="info statut rejete"
              *ngIf="
                getMappedDirectDebitSignatureStatus(file.signatureStatus) ==
                directDebitSignatureMappedStatus.REJECTED
              "
            >
              <img
                src="../../../../assets/images/Delete-five.svg"
                alt="'menu-icon"
              />
              {{ mappingDirectDebitSignatureStatus(file.signatureStatus) }}
            </p>
          </div>
          <div class="actions-container">
            <div *ngIf="!historyMode">
              <ng-container *ngIf="!file.signed && userCanSign">
                <div
                  class="file-action"
                  *ngIf="file.signatureStatus == 'PENDING'"
                >
                  <button class="action-btn" (click)="onSign(file?.id)">
                    <img
                      src="../../../../assets/images/signe.svg"
                      alt="'menu-icon"
                    />
                    <span> Signer </span>
                  </button>
                </div>
                <div
                  class="file-action"
                  *ngIf="file.signatureStatus == 'IN_PROGRESS' && !file.signed"
                >
                  <button class="action-btn" (click)="onSign(file?.id)">
                    <img
                      src="../../../../assets/images/signe.svg"
                      alt="'menu-icon"
                    />
                    <span> Contre signer </span>
                  </button>
                </div>
              </ng-container>
            </div>
            <div *ngIf="!historyMode && (userCanDisplayFile || userCanSign)">
              <button class="action-menu-btn" [matMenuTriggerFor]="menu">
                <img
                  src="../../../../assets/images/dots-menu.svg"
                  alt="'menu-icon"
                />
              </button>
              <mat-menu #menu="matMenu">
                <button
                  *ngIf="
                    !historyMode && userCanDisplayFile && !file?.isArchived
                  "
                  mat-menu-item
                  (click)="viewFile(file?.name)"
                >
                  <img
                    src="../../../../assets/images/oeil-plus.svg"
                    alt="'menu-icon"
                  />
                  <span>Visualiser</span>
                </button>
                <button
                  *ngIf="
                    !historyMode && userCanDisplayFile && !file?.isArchived
                  "
                  mat-menu-item
                  (click)="downloadDirectDebitFile(file?.name, file?.origin)"
                >
                  <img
                    src="../../../../assets/images/upload-cloud.svg"
                    alt="'menu-icon"
                  />
                  <span>Télécharger</span>
                </button>
                <button
                  *ngIf="
                    !historyMode &&
                    userCanSign &&
                    checkIfCancellable(file.signatureStatus)
                  "
                  (click)="openCancelConfirmPopup(file)"
                  mat-menu-item
                >
                  <img
                    src="../../../../assets/images/Delete-five.svg"
                    alt="'menu-icon"
                  />
                  <span>Annuler</span>
                </button>
              </mat-menu>
            </div>
          </div>
          <div class="upload-action" *ngIf="historyMode">
            <div
              class="icon"
              *ngIf="historyMode && file?.hasSortFile && !file?.isSortArchived"
              (click)="downloadSortFile(file?.id, file?.sortReference)"
              matTooltip="Télécharger le fichier Sort"
              matTooltipPosition="left"
            >
              <img
                src="../../../../assets/images/upload-cloud.svg"
                alt="'menu-icon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-pagination
      [page]="page"
      (getPage)="getCurrentPageDirectDebit()"
    ></app-pagination>
  </ng-template>

  <ng-template #noResultFound>
    <div class="files-container">
      <!--
      <h1 class="stdr">
        Vous n'avez aucune demande de prélèvement en attente de signature
      </h1>
      -->
    </div>
  </ng-template>
</div>

<!-------------------------------- Error management --------------------------------------->
<app-message-popin
  [openPopinFlag]="openPopinMessageFlag"
  mode=""
  [message]="openPopinMessage"
  [success]="openPopinMessageSuccess"
  (popinClosedEvent)="closePopin()"
></app-message-popin>
<!----------------------------------------------------------------------------------------->

<!-------------------------------- Signature management ----------------------------------->
<app-signature
  *ngIf="openPopinSignatureFlag"
  [challengeCode]="challengeCode"
  [challengeKey]="challengeKey"
  [loaderMode]="isPendingRefreshChalCodBtn || isSignaturePending"
  (refreshChallengeCode)="refreshChallengeCode()"
  (closeSignaturePopup)="closeSignaturePopin()"
  (onSign)="signHandler($event)"
></app-signature>
<!----------------------------------------------------------------------------------------->
<app-confirm-popin
  [openPopinFlag]="openCancelPopinConfirmFlag"
  message="Etes vous sûr de vouloir annuler votre fichier de prélèvement ?"
  (popinClosedEvent)="closeCancelConfirmPopin($event)"
></app-confirm-popin>
