<form [formGroup]="form">
  <div>
votre N° Telephone et le {{phoneNumber}}

</div>

<div>
  <label  *ngFor="let operator of operators">
  <input type="radio"   formControlName="options" 
  id="operator"
  [value]="operator" 
  [(ngModel)]="operatorSelect" 
  name="options"  (change)="selectOperator()">{{operator.libelle}}
</label>
  </div>

  <div>
    <input type="checkbox" class="justify-right"  >
  merci de valider votre numéro de telephone 
  </div>

  <div>
	<button  (click)="activateSoftToken()"  [disabled]="check" type="button"  class="btn btn-danger btn-arrow-right ">Activer le soft Token</button>
 </div>
</form>