<div class="signature-popup"></div>
<div class="signature-popup-container">
  <div class="signature-popup-header">
    <h5>Confirmation de votre signature</h5>
    <div class="close-container">
      <button
        type="button"
        class="btn btn-close"
        aria-label="Close"
        (click)="closeSignaturePopup.emit()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="signature-popup-file">
    <img
      *ngIf="loaderMode"
      class="loader-icon"
      src="/assets/images/Ellipsis-2s-80px.gif"
      alt=""
    />
    <div class="popin-container">
      <div>
        <img class="popin-image" src="/assets/images/signature.png" alt="" />
      </div>
      <div class="message1">
        <p>
          Code à saisir dans votre DIGIPASS ou niveau de votre application My
          Business Pass
          <span>{{ challengeCode }}</span>
        </p>
        <button class="refresh-code" (click)="refreshChallengeCd()">
          <img src="/assets/images/ic-refresh.svg" alt="" />
        </button>
      </div>
      <div class="message2">
        <p>
          Veuillez saisir le code affiché dans <b>DIGIPASS</b> ou niveau de
          votre application My Business Pass
        </p>
        <div class="input-container">
          <input
            class="signature-code"
            type="number"
            [(ngModel)]="signatureCode"
            name="signatureCode"
          />
          <!--<img src="/assets/images/ic_eye.svg" alt="" />-->
        </div>
      </div>
      <hr />
      <div class="action">
        <button class="default-btn big-size primary sign" (click)="sign()">
          Confirmer
          <img src="/assets/images/ic_check_white.svg" alt="" />
        </button>
      </div>
    </div>
  </div>
</div>
