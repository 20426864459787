import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Operator } from '../models/operator';

import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};
@Injectable({
  providedIn: 'root',
})
export class OperatorService {
  constructor(private http: HttpClient) {}

  public getOperators(): Observable<Operator[]> {
    return this.http.get<Operator[]>(
      `${environment.apiUrl}/operator`,
      httpOptions
    );
  }
}
