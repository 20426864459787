import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';



export abstract class Util{
  static date_input_config = {
    containerClass: "theme-default",
    customTodayClass: "bs-today",
    showWeekNumbers: false,
    dateInputFormat: "DD/MM/YYYY"
  };

  static currencies = [
    "EUR",
    "USD",
    "CAD",
    "GBP",
    "CHF",
    "DKK",
    "SEK",
    "NOK",
    "SAR",
    "KWD",
    "AED",
    "JPY",
    "DZD",
    "TND",
    "RUB",
    "CNY",
    "TRY",
    "CFA",
    "MAD"
  ];

  static codesBankeTire = [
    {
      code:'001',
      label: 'Banque Al Maghrib',
    },
    {
      code:'002',
      label: 'Arab Bank',
    },
    {
      code:'003',
      label: 'CAIXA',
    },
    {
      code:'005',
      label: 'U.M.B',
    },
    {
      code:'007',
      label: 'A.W.B',
    },
    {
      code:'011',
      label: 'B.M.C.E',
    },
    {
      code:'013',
      label: 'B.M.C.I',
    },
        {
      code:'019',
      label: 'A.W.B (Ex Wafabank)',
    },
    {
      code:'021',
      label: 'C.D.M',
    },
    {
      code:'022',
      label: 'S.G.M.B',
    },
    {
      code:'023',
      label: 'ABN AMRO',
    },
    {
      code:'025',
      label: 'C.A.M (Ex B.M.A.O)',
    },
    {
      code:'026',
      label: 'Banque fusionnee avec 019',
    },
    {
      code:'028',
      label: 'Citibank Maghreb',
    },
    {
      code:'031',
      label: 'S.M.D.C',
    },
    {
      code:'032',
      label: 'BEX',
    },
    {
      code:'040',
      label: 'B.C.P',
    },
    {
      code:'045',
      label: 'BANK AL AMAL',
    },
    {
      code:'050',
      label: 'CFG',
    },
    {
      code:'054',
      label: 'CDG CAPITAL',
    },
    {
      code:'060',
      label: 'CDG',
    },
    {
      code:'070',
      label: 'SABADELL',
    },
    {
      code:'101',
      label: 'BP Centre Sud',
    },
    {
      code:'105',
      label: 'BP Succ. El Hoceima',
    },
    {
      code:'109',
      label: 'BP Succ. Beni-Mellal',
    },
    {
      code:'117',
      label: 'BP El Jadida Safi',
    },
    {
      code:'121',
      label: 'BP',
    },
    {
      code:'127',
      label: 'BP Fès Taza',
    },
    {
      code:'133',
      label: 'BP Succ. Gharb',
    },
    {
      code:'143',
      label: 'BP Laayoune',
    },
    {
      code:'145',
      label: 'BP Marrakech Beni Mellal',
    },
    {
      code:'148',
      label: 'BP Meknès',
    },
    {
      code:'150',
      label: 'BP Nador El Hoceima',
    },
    {
      code:'155',
      label: 'BP Succ. Ouarzazate',
    },
        {
      code:'157',
      label: 'BP Oujda',
    },
    {
      code:'159',
      label: 'BP Succ. SAFI',
    },
    {
      code:'163',
      label: 'BP',
    },
    {
      code:'164',
      label: 'BP Tanger Tetouan',
    },
    {
      code:'169',
      label: 'BP Succ. Taza',
    },
    {
      code:'172',
      label: 'BP Succ. Tetouan',
    },
    {
      code:'175',
      label: 'BP Succ. Tiznit',
    },
    {
      code:'178',
      label: 'BP Casablanca',
    },
    {
      code:'181',
      label: 'BP Rabat Kenitra',
    },
    {
      code:'190',
      label: 'B.C.P',
    },
    {
      code:'195',
      label: 'BP Succ. Ben Slimane',
    },
    {
      code:'197',
      label: 'BP Succ. Casa Sud',
    },
    {
      code:'205',
      label: 'B.N.D.E',
    },
    {
      code:'210',
      label: 'CDG',
    },
    {
      code:'225',
      label: 'C.A.M',
    },
    {
      code:'230',
      label: 'C.I.H',
    },
    {
      code:'310',
      label: 'T.G.R',
    },
    {
      code:'350',
      label: 'Poste du Maroc',
    },
    {
      code:'360',
      label: 'UMNIA BANK',
    },
    {
      code:'361',
      label: 'NAJMAH BANK',
    },
    {
      code:'362',
      label: 'BANK ASSAFA',
    },
    {
      code:'363',
      label: 'DAR AL AMANE',
    },
    {
      code:'364',
      label: 'AREDDA BANK',
    },
    {
      code:'365',
      label: 'AL AKHDAR BANK',
    },
    {
      code:'366',
      label: 'BANK Al YOUSR',
    },
    {
      code:'367',
      label: 'BTI BANK',
    },
  ];


  public static transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter(it => {
      return JSON.stringify(it).toLocaleLowerCase().includes(searchText);
    });
  }
}

export function initializer(keycloak: KeycloakService): () => Promise<any> {
    return (): Promise<any> => {
        return new Promise(async (resolve, reject) => {
          try {
            await keycloak.init({
                config: {
                    url: environment.keycloak.issuer,
                    realm: environment.keycloak.realm,
                    clientId: environment.keycloak.clientId
                },
              loadUserProfileAtStartUp: false,
              initOptions: {
                onLoad: 'check-sso',
                checkLoginIframe: true
              },
              enableBearerInterceptor: true,
              bearerExcludedUrls: ['http://localhost:4200/soft-token?uuid=123456789']
            });
            resolve();
          } catch (error) {
            reject(error);
          }
        });
      };
}