import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { AccountsDashboardComponent } from './accounts-dashboard/accounts-dashboard.component';
import { WaitingSignatureComponent } from './waiting-signature/waiting-signature.component';
import { ApplicationsComponent } from './applications/applications.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CotationsComponent } from './cotations/cotations.component';
import { SharedModule } from '../shared-module/shared.module';
import { NgxSelectModule } from 'ngx-select-ex';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AssistanceComponent } from './assistance/assistance.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AssitanceDetailRequestComponent } from './assistance/assitance-detail-request/assitance-detail-request.component';
import { SoftTokenNewComponent } from '../../components/soft-token-new/soft-token-new.component';
import { SoftTokenActivateComponent } from '../../components/soft-token-activate/soft-token-activate.component';
import { MatSnackBarModule, MatSidenavModule } from '@angular/material';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressSpinnerModule } from '@angular/material';
import { NewsListComponent } from './news/news-list/news-list.component';
import { NewsElemComponent } from './news/news-elem/news-elem.component';
import { FooterListComponent } from './footer/footer-list/footer-list.component';
import { FooterElemComponent } from './footer/footer-elem/footer-elem.component';

@NgModule({
  declarations: [
    AccountsDashboardComponent,
    WaitingSignatureComponent,
    CotationsComponent,
    ApplicationsComponent,
    DashboardComponent,
    CotationsComponent,
    AssistanceComponent,
    AssitanceDetailRequestComponent,
    SoftTokenNewComponent,
    SoftTokenActivateComponent,
    NewsListComponent,
    NewsElemComponent,
    FooterListComponent,
    FooterElemComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    FormsModule,
    NgxSelectModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatStepperModule,
    MatProgressSpinnerModule
  ],
  exports: [
    AccountsDashboardComponent,
    ApplicationsComponent,
    WaitingSignatureComponent,
    SoftTokenNewComponent,
    SoftTokenActivateComponent
  ]
})
export class DashboardModule {}
