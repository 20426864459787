import { Component, OnInit, Inject, Output, EventEmitter, ViewChild } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import {UserService} from '../../service/user.service';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/models/user';
import { SoftTokenActivationService } from 'src/app/service/soft-token-activation.service';
import { AdvisorService } from 'src/app/service/advisor.service';
import { Client } from 'src/app/models/client';
import { FormControl } from '@angular/forms';
import { ClientService } from 'src/app/service/client.service';
import { ClientSharedService } from 'src/app/service/client-shared.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AccountsDashboardComponent } from 'src/app/modules/dashboard/accounts-dashboard/accounts-dashboard.component';
import { AssistanceComponent } from 'src/app/modules/dashboard/assistance/assistance.component';

const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement => input !== null && input.tagName === 'IFRAME';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  clients: Array<Client>;
  public clientFormControl = new FormControl();
  public defaultClientFormControlValue: Client;
  showDropDownClientSelect = false;
  options: string[] = [];
  displayMutliclient : boolean = true;
  displayMsgMutliclient : boolean = false;

  public userFullName: string;
  @Output() logoutDetails = new EventEmitter<void>();
  HIDE_MODAL = 'hideModal';
  SHOW_MODAL = 'showModal';
  LIST = 'list';
  modeleModalData: { showClass: string; type: string } = {
    showClass: this.HIDE_MODAL,
    type: null
  };

  public user: User
  //constructor(private keycloakService: KeycloakService) { }
  constructor(@Inject(KeycloakService) service: KeycloakService,
    private keycloakService: KeycloakService,
    private softTokenActivationService : SoftTokenActivationService,
    private userService: UserService,
    private advisorService: AdvisorService,
    private clientService: ClientService,
    private router: Router,
    public dialog: MatDialog,
    private clientSharedService: ClientSharedService
  ) {

    this.userService.getUser()
        .subscribe((user: User) => {
            this.user = user;
            localStorage.setItem('username', this.user.username);
            localStorage.setItem('name', this.user.firstName + ' ' + this.user.lastName);
            localStorage.setItem('cguAccepted', this.user.cguAccepted ? 'true' : 'false');
          });
  }

  async ngOnInit(): Promise<void> {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    if (window.location.pathname && window.location.pathname.includes('SOGECASHNET')) {
      this.displayMutliclient = false;
    } else {
      this.displayMutliclient = true;
    }

    //await this.userService.setInfoUser();

    /*this.userService.getUser().subscribe(
      user => {
        this.user = user;
        localStorage.setItem('name', this.user.firstName+" " + this.user.lastName);
        this.userFullName =  this.user.firstName+" " + this.user.lastName;
        //localStorage.setItem('username', this.user.username);
        this.softTokenActivationService.getSoftToken().subscribe(
          hastoken => {
            localStorage.setItem('hasSoftToken', hastoken.toString());
          }
        );
      }
    );*/


    await this.clientService.getCustomerClient().pipe(map((obj) => {
      let customNumberArray = {};
      try {
        customNumberArray = JSON.parse(localStorage.getItem('customer_number'));
      } catch(err) {
        customNumberArray = {};
      }
      if (!customNumberArray) {
        customNumberArray = {};
      }

      if (obj && obj.customerNumber) {
        if (obj.customerNumber.includes('customerNumber')) {
          customNumberArray[window.location.pathname] = JSON.parse(obj.customerNumber).customerNumber;
          customNumberArray['expertComp'] = JSON.parse(obj.customerNumber).customerNumber;
          if (window.location.pathname === '/') {
            customNumberArray['/dashboard'] = JSON.parse(obj.customerNumber).customerNumber;
          }
        } else {
          customNumberArray[window.location.pathname] = obj.customerNumber;
          customNumberArray['expertComp'] = obj.customerNumber;
          if (window.location.pathname === '/') {
            customNumberArray['/dashboard'] = obj.customerNumber;
          }
        }
        localStorage.setItem('customer_number', JSON.stringify(customNumberArray));
      }
    })).toPromise().then(() => {

      this.softTokenActivationService.getSoftToken().subscribe(
        hastoken => {
          localStorage.setItem('hasSoftToken', hastoken.toString());
        }
      );

      this.getClientsForConnectedUser();
    });

    setTimeout((_this = this) => {
      _this.startSearchUpdatedCustomerNumber();
    }, 10000);
  }

  startSearchUpdatedCustomerNumber(): void {
    setInterval((_this = this) => {
      _this.clientService.getCustomerClient().subscribe((obj) => {
        let customerNumberCache;

        if (obj && obj.customerNumber) {
          if (obj.customerNumber.includes('customerNumber')) {
            customerNumberCache = JSON.parse(obj.customerNumber).customerNumber;
          } else {
            customerNumberCache = obj.customerNumber;
          }
        }

        let customNumberArray = JSON.parse(localStorage.getItem('customer_number'));

        if (customNumberArray && customNumberArray[window.location.pathname] && customerNumberCache
              && customNumberArray[window.location.pathname] !== customerNumberCache) {

          customNumberArray[window.location.pathname] = customerNumberCache;
          customNumberArray['expertComp'] = customerNumberCache;
          localStorage.setItem('customer_number', JSON.stringify(customNumberArray));

          this.clientFormControl.setValue(customerNumberCache);

          if (_this.displayMutliclient) {
            _this.router.navigate(['/refreshPath', window.location.pathname]);
          }
        }
      });
    }, 3000);
  }

  loadWidgets(): void {
    this.clientFormControl.valueChanges.subscribe((value) => {
      const customerNumber = value != null ? value : this.defaultClientFormControlValue.customerNumber;

      let customNumberArray = JSON.parse(localStorage.getItem('customer_number'));
      this.clientSharedService.addClient(customerNumber);

      if (customNumberArray && customNumberArray[window.location.pathname] && customerNumber
            && customNumberArray[window.location.pathname] !== customerNumber) {

        this.clientService.storeCustomerClient(customerNumber).subscribe((obj) => {});

        customNumberArray[window.location.pathname] = customerNumber;
        customNumberArray['expertComp'] = customerNumber;

        localStorage.setItem('customer_number', JSON.stringify(customNumberArray));
        this.router.navigate(['/refreshPath', window.location.pathname]);
      }

    });
  }

  getClientsForConnectedUser(): void {
    this.clientService
      .getClients()
      .toPromise()
      .then((clients) => {
        if (!this.displayMutliclient && clients.length > 1) {
          this.displayMsgMutliclient = true;
        } else {
          this.displayMsgMutliclient = false;
        }

        this.clients = clients;
        this.showDropDownClientSelect = true;
        if (localStorage.getItem('customer_number')) {
          let customNumberArray = JSON.parse(localStorage.getItem('customer_number'));

          this.defaultClientFormControlValue = clients.find((client) => {
            return client.customerNumber === customNumberArray[window.location.pathname]
          });
        } else {
          this.defaultClientFormControlValue = clients[0];
        }

        this.loadWidgets();

        setTimeout((_this = this) => {
          if (window.location.pathname === '/') {
            this.router.navigate(['/refreshPath', '/dashboard']);
          }
        }, 2000);
      });
  }


  status: boolean = false
  clickEvent(){
      this.status = !this.status;
  }

  onClickedOutside(e: Event){
      this.status = false;
  }
  logout(){
    localStorage.clear();
    this.keycloakService.logout();
  }
  getFullName(): string{
    return localStorage.getItem('name')
  }

  public onDetails(): void {
    this.logoutDetails.emit();
  }

  selectReview(reviewId: string) {

  }

  callAdviser() {
    this.advisorService.menuStatus.next('-1');
    const dialogRefs = this.dialog.open(AssistanceComponent, {
      data: {},
      disableClose: true
    });
  }

  openSideBar() {
    document.querySelector('[id="rightSideBar"]').classList.add('active');
  }



}
