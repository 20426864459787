import { Component, Input, OnInit } from '@angular/core';
import { Page } from '../../../models/page';
import { ExternalTransferService } from '../../../service/external-transfer.service';
import { GetFileRequestDto } from '../models/getFileRequestDto';
import { GetFileResponseDto } from '../models/getFileResponseDto';
import { ExternalTransferClient } from '../models/externalTransferClient';

@Component({
  selector: 'app-signed',
  templateUrl: './signed.component.html',
  styleUrls: ['./signed.component.scss'],
})
export class SignedComponent implements OnInit {
  files: Array<GetFileResponseDto>;
  page = new Page();
  fileProcessRequest: GetFileRequestDto = new GetFileRequestDto();
  searchCriteria: string;
  noFilesFound;
  @Input() client: ExternalTransferClient;

  constructor(private externalTransfer: ExternalTransferService) {
    this.page.init();
    this.fileProcessRequest.reference = '';
  }

  ngOnInit(): void {
    this.getCurrentPageTransfers();
  }

  getCurrentPageTransfers(): void {
    this.externalTransfer
      .getFilesWithSignatureCompleted(
        this.client.id,
        this.page.currentPage,
        this.fileProcessRequest
      )
      .toPromise()
      .then((result) => {
        this.files = result.contentList;
        this.page.totalPages = result.totalPages;
        this.page.totalElements = result.totalElements;
        this.noFilesFound =
          result.contentList == null || result.contentList.length <= 0;
      })
      .catch(() => {});
  }

  searchFileProcess(): void {
    this.page.currentPage = 0;
    this.fileProcessRequest.reference = this.searchCriteria;
    this.getCurrentPageTransfers();
  }
}
