import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SoftTokenDetailsComponent } from './soft-token-popin/soft-token-details/soft-token-details.component';
import { SoftTokenPopinComponent } from './soft-token-popin/soft-token-popin.component';
import { OnBoadingFirstStepComponent } from './on-boading-first-step/on-boading-first-step.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [
    SoftTokenDetailsComponent,
    SoftTokenPopinComponent,
    OnBoadingFirstStepComponent
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [SoftTokenDetailsComponent, SoftTokenPopinComponent]
})
export class SoftTokenModuleModule {}
