import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ClientService } from 'src/app/service/client.service';
import { UserClientConfigService } from 'src/app/service/user-client-config.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BalanceSheetGuard implements  CanActivate{
  constructor(
    private userClientConfigService: UserClientConfigService,
    private clientService: ClientService,
    private router: Router
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return new Promise((resolve, reject) => {
      this.clientService.getCustomerClient().subscribe({
        next: (obj) => {
                  this.userClientConfigService.balanceSheetCheckPopUp(obj.customerNumber, false).subscribe({ 
                    next: (value: boolean) =>  {
                      if(!value)this.router.navigate(["/"]);
                      resolve(value);
                    },
                    error: (error)=> {
                      this.router.navigate(["/"]);
                      reject(false);
                    }
                  });
                },
        error: (error)=> {
          this.router.navigate(["/"]);
          reject(false);
        }
      });
    });
  }
  
}
