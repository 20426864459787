export class SignMessages {
  public signMessage: string;
  public signatureSuccessMessage: string;
  public signatureErrorMessage: string;


  constructor(signMessage: string, signatureSuccessMessage: string, signatureErrorMessage: string) {
    this.signMessage = signMessage;
    this.signatureSuccessMessage = signatureSuccessMessage;
    this.signatureErrorMessage = signatureErrorMessage;
  }
}
