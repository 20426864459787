<div class="main-popin-container show" *ngIf="openPopinFlag">
  <div class="popin-content-body small-popin">
    <div class="popin-container">
      <div class="message">
          <p>{{message}}</p>
      </div>
      <div class="validation-action">
        <button class="default-btn big-size upload-btn" (click)="response(true)">Oui</button>
        <button class="default-btn big-size init-btn" (click)="response(false)" >Non</button>
      </div>
    </div>
  </div>
</div>