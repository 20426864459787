import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogModule,MatDialogRef,MAT_DIALOG_DATA } from "@angular/material/";
@Component({
  selector: 'app-assitance-detail-request',
  templateUrl: './assitance-detail-request.component.html',
  styleUrls: ['./assitance-detail-request.component.scss']
})
export class AssitanceDetailRequestComponent implements OnInit {
  subject: string = '';
  message: string = '';
  numTicket: string ='';
  constructor(
    private dialogRef: MatDialogRef<AssitanceDetailRequestComponent>,
    @Inject(MAT_DIALOG_DATA) data: { subject: string; message: string,numTicket: string }
  ) {
    this.subject = data ? data.subject : '';
    this.message = data ? data.message : '';
    this.numTicket = data ? data.numTicket : '';
  }
  ngOnInit(): void {
  }
  closeAlert() {
    this.dialogRef.close();
  }
}
