import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Expert } from '../models/expert';
import { environment } from '../../environments/environment';
import { EmailExpert } from '../models/emailExpert';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class ExpertService {
  constructor(private http: HttpClient) {}

  public findExpert(): Observable<Expert[]> {
    return this.http.get<Expert[]>(
      `${environment.apiUrl}/experts`,
      httpOptions
    );
  }

  public sendMailToExpert(emailExpert: EmailExpert): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/experts/send-mail-expert`,
      emailExpert,
      httpOptions
    );
  }
}
