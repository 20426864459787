<div class="breadcrumb">
  <span class="item has-arrow">Accueil</span>
  <span class="item has-arrow">Services à activer</span>
  <span class="item">Soft Token</span>
</div>

<!-- ############# main page ############# -->

<!-- <div class="tutorial-main">
  <div class="content">
    <img src="/assets/images/softToken/tutorial-main.svg" alt="tutorial" />
    <p class="title">
      <b class="title-red ">MYBUSINESS PASS </b>
      est la solution mobile pour vous authentifier sur la solution
      <b class="special"> MYBUSINESS</b>
    </p>
    <p class="subtitle">
      Elle vous permet de vous connecter à votre compte MYBUSINESS et effectuer
      vos opérations en ligne, en toute simplicité et sécurité. Avec le
      <b>Digipass</b> Mobile, vous n’aurez plus besoin de vous encombrer d’un
      <b>Digipass</b> physique. Découvrew comment vous authentifier et effectuer
      vos opérations en ligne.
    </p>
    <div class="ctas">
      <button type="button" class="btn-icon-new check active">
        <span>ACTIVER LE SOFT-TOKEN</span>
      </button>
      <button type="button" class="btn-icon-new tutorial">
        <span>VOIR LE TUTORIEL</span>
      </button>
    </div>
  </div>
</div> -->

<!-- ############# tutorial ############# -->
<div class="tutorial-main">


  <!-- ############# step 1 ############# -->
  <div class="content">
    <img src="/assets/images/softToken/tutorial_step1.svg" alt="tutorial" />
    <p class="title-red">
      TÉLÉCHARGEMENT
    </p>
    <p class="subtitle">
      Elle vous permet de vous connecter à votre compte MYBUSINESS et effectuer
      vos opérations en ligne, en toute simplicité et sécurité. Avec le Digipass
      Mobile, vous n’aurez plus besoin de vous encombrer d’un Digipass physique.
      Découvrew comment vous authentifier et effectuer vos opérations en ligne.
    </p>
  </div>

  <!-- ############# step 2 ############# -->
  <!-- <div class="content">
    <img src="/assets/images/softToken/tutorial_step2.svg" alt="tutorial" />
    <p class="title-red">
      MAIL D’ACTIVATION
    </p>
    <p class="subtitle">
      Elle vous permet de vous connecter à votre compte MYBUSINESS et effectuer
      vos opérations en ligne, en toute simplicité et sécurité. Avec le Digipass
      Mobile, vous n’aurez plus besoin de vous encombrer d’un Digipass physique.
      Découvrew comment vous authentifier et effectuer vos opérations en lign e.
    </p>
  </div> -->

  <!-- ############# step 3 ############# -->
  <!-- <div class="content">
    <img src="/assets/images/softToken/tutorial_step3.svg" alt="tutorial" />
    <p class="title-red">
      Confirmation de numéro de téléphone
    </p>
    <p class="subtitle">
      Elle vous permet de vous connecter à votre compte MYBUSINESS et effectuer
      vos opérations en ligne, en toute simplicité et sécurité. Avec le Digipass
      Mobile, vous n’aurez plus besoin de vous encombrer d’un Digipass physique.
      Découvrew comment vous authentifier et effectuer vos opérations en ligne.
    </p>
  </div> -->

  <!-- ############# step 4 ############# -->
  <!-- <div class="content">
    <img src="/assets/images/softToken/tutorial_step4.svg" alt="tutorial" />
    <p class="title-red">
      Scan du 1er Qr Code
    </p>
    <p class="subtitle">
      Elle vous permet de vous connecter à votre compte MYBUSINESS et effectuer
      vos opérations en ligne, en toute simplicité et sécurité. Avec le Digipass
      Mobile, vous n’aurez plus besoin de vous encombrer d’un Digipass physique.
      Découvrew comment vous authentifier et effectuer vos opérations en ligne.
    </p>
  </div> -->

  <!-- ############# step 5 ############# -->
  <!-- <div class="content">
    <img src="/assets/images/softToken/tutorial_step5.svg" alt="tutorial" />
    <p class="title-red">
      Composition du code
    </p>
    <p class="subtitle">
     Elle vous permet de vous connecter à votre compte MYBUSINESS et effectuer vos opérations en ligne, en toute simplicité et sécurité. Avec le Digipass Mobile, vous n’aurez plus besoin de vous encombrer d’un Digipass physique. Découvrew comment vous authentifier et effectuer vos opérations en ligne.
    </p>
  </div> -->

  <!-- ############# step 6 ############# -->
  <!-- <div class="content">
    <img src="/assets/images/softToken/tutorial_step6.svg" alt="tutorial" />
    <p class="title-red">
      Scan du 2eme Qr code
    </p>
    <p class="subtitle">
      Elle vous permet de vous connecter à votre compte MYBUSINESS et effectuer
      vos opérations en ligne, en toute simplicité et sécurité. Avec le Digipass
      Mobile, vous n’aurez plus besoin de vous encombrer d’un Digipass physique.
      Découvrew comment vous authentifier et effectuer vos opérations en ligne.
    </p>
  </div> -->

  <!-- ############# step 7 ############# -->
  <!-- <div class="content">
    <img src="/assets/images/softToken/tutorial_step7.svg" alt="tutorial" />
    <p class="title-red">
      Choisir son propre code pin
    </p>
    <p class="subtitle">
      Elle vous permet de vous connecter à votre compte MYBUSINESS et effectuer
      vos opérations en ligne, en toute simplicité et sécurité. Avec le Digipass
      Mobile, vous n’aurez plus besoin de vous encombrer d’un Digipass physique.
      Découvrew comment vous authentifier et effectuer vos opérations en ligne.
    </p>
  </div> -->

  <!-- ############# step 8 ############# -->
  <!-- <div class="content">
    <img src="/assets/images/softToken/tutorial_step8.svg" alt="tutorial" />
    <p class="title-red">
      Acceder à la page de connexion Mybusiness Web
    </p>
    <p class="subtitle">
      Elle vous permet de vous connecter à votre compte MYBUSINESS et effectuer
      vos opérations en ligne, en toute simplicité et sécurité. Avec le Digipass
      Mobile, vous n’aurez plus besoin de vous encombrer d’un Digipass physique.
      Découvrew comment vous authentifier et effectuer vos opérations en ligne.
    </p>
  </div> -->

  <!-- ############# step 9 ############# -->
  <!-- <div class="content">
    <img src="/assets/images/softToken/tutorial_step9.svg" alt="tutorial" />
    <p class="title-red">
      Saisir le code pin pour générer un mot de pass sur l’application DIGIPASS
    </p>
    <p class="subtitle">
      Elle vous permet de vous connecter à votre compte MYBUSINESS et effectuer
      vos opérations en ligne, en toute simplicité et sécurité. Avec le Digipass
      Mobile, vous n’aurez plus besoin de vous encombrer d’un Digipass physique.
      Découvrew comment vous authentifier et effectuer vos opérations en ligne.
    </p>
  </div> -->

  <!-- ############# step 10 ############# -->
  <!-- <div class="content">
    <img src="/assets/images/softToken/tutorial_step10.svg" alt="tutorial" />
    <p class="title-red">
     Affichage du mot de passe apres la saisie du code pin
    </p>
    <p class="subtitle">
      Elle vous permet de vous connecter à votre compte MYBUSINESS et effectuer
      vos opérations en ligne, en toute simplicité et sécurité. Avec le Digipass
      Mobile, vous n’aurez plus besoin de vous encombrer d’un Digipass physique.
      Découvrew comment vous authentifier et effectuer vos opérations en ligne.
    </p>
  </div> -->

  <!-- ############# step 11 ############# -->
  <!-- <div class="content">
    <img src="/assets/images/softToken/tutorial_step11.svg" alt="tutorial" />
    <p class="title-red">
      Taper le mot de passe sur la page web de MYBUSINESS
    </p>
    <p class="subtitle">
      Elle vous permet de vous connecter à votre compte MYBUSINESS et effectuer
      vos opérations en ligne, en toute simplicité et sécurité. Avec le Digipass
      Mobile, vous n’aurez plus besoin de vous encombrer d’un Digipass physique.
      Découvrew comment vous authentifier et effectuer vos opérations en ligne.
    </p>
  </div> -->

  <div class="pagination-v2">
    <button class="prev" (click)="indicator('decrement')">
      <img src="/assets/images/ic_arrow_next.svg" alt="previous" />
    </button>
    <button>
      <mat-progress-spinner
        mode="determinate"
        color="warn"
        [value]="value"
        strokeWidth="1"
        diameter="30"
      >
      </mat-progress-spinner>
      <span class="spinner-number">{{ value / 10 }}</span>
    </button>
    <button class="more">
      <span>...</span>
    </button>
    <button class="next" (click)="indicator('increment')">
      <img src="/assets/images/ic_arrow_next.svg" alt="next" />
    </button>
  </div>
</div>
