import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { SignatureChallengeAndDresponse } from 'src/app/models/signatureChallengeAndDresponse';
import { ChallengeService } from 'src/app/service/challenge.service';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent implements OnInit {
  @Output() closeSignaturePopup = new EventEmitter<void>();
  @Output() onSign = new EventEmitter<SignatureChallengeAndDresponse>();
  @Output() refreshChallengeCode = new EventEmitter<void>();
  @Input() loaderMode = false;
  @Input() challengeCode: string;
  @Input() challengeKey: string;

  signatureCode: string;

  constructor(private challengeService: ChallengeService) {}

  ngOnInit() {}

  refreshChallengeCd() {
    this.refreshChallengeCode.emit();
  }

  sign(): void {
    this.onSign.emit(
      new SignatureChallengeAndDresponse(this.challengeKey, this.signatureCode)
    );
  }

  /**
onCancel(): void {
  this.resetForm();
  this.cancelled.emit();
}

resetForm(): void {
  this.signForm.reset();
}

onRefresh(event: string): void {
  // this.loadCallenge();
}
   */
}
