import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectDebitRoutingModule } from './direct-debit-routing.module';
import { DbDashboardComponent } from './db-dashboard/db-dashboard.component';
import { DbToSignComponent } from './db-to-sign/db-to-sign.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared-module/shared.module';
import { DbInitComponent } from './db-init/db-init.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import {
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
  MatTooltipModule,
} from '@angular/material';

@NgModule({
  declarations: [DbDashboardComponent, DbToSignComponent, DbInitComponent],
  imports: [
    CommonModule,
    DirectDebitRoutingModule,
    FormsModule,
    SharedModule,
    NgxDropzoneModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
  ],
})
export class DirectDebitModule {}
