import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClickOutsideModule } from 'ng4-click-outside';
import { SharedModule } from '../shared-module/shared.module';
import { InitBeneficiaryComponent } from './beneficiaries/init-beneficiary/init-beneficiary.component';
import { BeneficiariesSignedComponent } from './beneficiaries/signed/beneficiaries-signed.component';
import { BeneficiariesToSignComponent } from './beneficiaries/to-sign/beneficiaries-to-sign.component';
import { DialogSignComponent } from './dialog-sign/dialog-sign.component';
import { InitProvisionComponent } from './provision/init-provision/init-provision.component';
import { ProvisionDashboardComponent } from './provision/provision-dashboard/provision-dashboard.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { AlertComponent } from './alert/alert.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { ProvisionSignedComponent } from './provision/signed/provision-signed.component';

@NgModule({
  declarations: [
    SideMenuComponent,
    ProvisionDashboardComponent,
    InitProvisionComponent,
    BeneficiariesSignedComponent,
    BeneficiariesToSignComponent,
    InitBeneficiaryComponent,
    DialogSignComponent,
    AlertComponent,
    ProvisionSignedComponent,
  ],
  imports: [
    CommonModule,
    ClickOutsideModule,
    RouterModule,
    ReactiveFormsModule,
    SharedModule,
    NgxSelectModule,
  ],
  exports: [BeneficiariesSignedComponent],
})
export class ProvisionModule {}
