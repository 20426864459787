import { Component, OnInit } from '@angular/core';
import { DirectDebitSignatureStatus } from 'src/app/models/DirectDebitSignatureStatus';
import { ClientService } from 'src/app/service/client.service';
import { UserService } from 'src/app/service/user.service';
import { Roles } from 'src/app/utils/roles';

@Component({
  selector: 'app-db-dashboard',
  templateUrl: './db-dashboard.component.html',
  styleUrls: ['./db-dashboard.component.scss'],
})
export class DbDashboardComponent implements OnInit {
  tabActive;
  isDataLoaded = false;
  toSignTabSignatureStatus: DirectDebitSignatureStatus[] = [
    DirectDebitSignatureStatus.PENDING,
    DirectDebitSignatureStatus.IN_PROGRESS,
  ];
  hisoryTabStatus: DirectDebitSignatureStatus[] = [
    DirectDebitSignatureStatus.UNPAID,
    DirectDebitSignatureStatus.REJECTED,
    DirectDebitSignatureStatus.PAID,
    DirectDebitSignatureStatus.CANCELED,
    DirectDebitSignatureStatus.EXECUTING,
    DirectDebitSignatureStatus.EXECUTED,
    DirectDebitSignatureStatus.COMPLETED,
    DirectDebitSignatureStatus.WAITING_EXECUTION,
    DirectDebitSignatureStatus.WAITING_VALIDATION,
    DirectDebitSignatureStatus.WAITING_REJECTION,
  ];

  userCanConsult: boolean;
  userCanSign: boolean;
  userCanDisplayFile: boolean;
  userCanImport: boolean;

  constructor(
    private userService: UserService,
    private clientService: ClientService
  ) {}

  async ngOnInit() {
    this.checkUserRoles(
      await this.clientService.getCustomerNumberFromLocalStorage()
    );
  }

  selectTab(tabLabel: string): void {
    this.tabActive = tabLabel;
  }

  isActive(tabLabel: string): boolean {
    return this.tabActive === tabLabel;
  }

  checkUserRoles(customerNumber) {
    this.userService
      .getUserRolesByCustomerNumber(
        customerNumber,
        localStorage.getItem('username')
      )
      .toPromise()
      .then((result) => {
        if (result.userRoles && result.userRoles.length > 0) {
          this.userCanConsult = Roles.hasRole(
            result,
            Roles.DIRECT_DEBIT_VIEWER
          );
          this.userCanSign = Roles.hasRole(
            result,
            Roles.DIRECT_DEBIT_SIGNATORY
          );
          this.userCanDisplayFile = Roles.hasRole(
            result,
            Roles.DIRECT_DEBIT_CHECKER
          );
          this.userCanImport = Roles.hasRole(
            result,
            Roles.DIRECT_DEBIT_INITIATOR
          );
        }

        if (this.userCanImport) this.tabActive = 'INIT';
        else if (
          this.userCanConsult ||
          this.userCanSign ||
          this.userCanDisplayFile
        )
          this.tabActive = 'TO-SIGN';
        else this.tabActive = 'ROLES-ERROR';
      });
  }
}
