import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/service/user.service';
import { ServicesService } from 'src/app/service/services.service';
import { SoftTokenActivationService } from 'src/app/service/soft-token-activation.service';
import { Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { Application } from '../../../models/application';
import { KeycloakService } from 'keycloak-angular';
import { Client } from '../../../models/client';
import { Services } from 'src/app/models/services';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
  animations: [
    // animation triggers go here
    trigger('openClose', [
      state(
        'open',
        style({ opacity: 1, visibility: 'visible', height: '100%' })
      ),
      transition('void => *', [
        style({ opacity: 0, visibility: 'hidden', height: '50%' }),
        animate(100),
      ]),
      transition('* => void', [
        animate(
          100,
          style({ opacity: 0, visibility: 'hidden', height: '50%' })
        ),
      ]),
    ]),
  ],
})
export class ApplicationsComponent implements OnInit {
  apps = [];
  showActivatedServices: boolean = true;
  public user: User;
  public cguAccepted: boolean = false;
  public userApps: Application[];
  public othersApps: Application[];
  public services: Services[];

  public selectedApplication: Application;

  public showNewsDetails = false;
  customerNumber: string;

  public status = false;
  clients: Client[];
  msg: string;

  constructor(
    private userService: UserService,
    private servicesService: ServicesService,
    private keycloakService: KeycloakService,
    private softTokenActivationService: SoftTokenActivationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.msg = null;
    this.servicesService
      .getServices()
      .subscribe((data) => (this.services = data));
    //this.clearStorage();
    //this.userService.setInfoUser();
    this.getApps();
  }

  clearStorage() {
    localStorage.removeItem('username');
    localStorage.removeItem('name');
    localStorage.removeItem('cguAccepted');
    localStorage.removeItem('ALL_APPS');
  }

  updateShowedServices() {
    this.showActivatedServices = !this.showActivatedServices;
  }

  getCgu(): boolean {
    if (this.cguAccepted) return true;
    const cguValue = localStorage.getItem('cguAccepted');
    if (cguValue) {
      return JSON.parse(cguValue);
    }
    return true;
  }

  public getApps(): Application[] {
    if (localStorage.getItem('ALL_APPS')) {
      return this.treatApps();
    } else {
      this.userService
        .getUser()
        .pipe(
          map((user: User) => {
            this.user = user;
            let apps = [];
            this.user.applications.forEach((app) => {
              apps.push(app.code);
            });
            return apps;
          })
        )
        .toPromise()
        .then((codes: string[]) => {
          let allApps = [];
          this.userService.getAllApplications().subscribe((applications) => {
            applications.forEach((app) => {
              if (codes.includes(app.code)) {
                app.assigned = true;
              } else {
                app.assigned = false;
              }
              allApps.push(app);
            });

            // sort by value
            allApps.sort((a, b) => {
              if (a.appOrder < b.appOrder) {
                return -1;
              }
              if (a.appOrder > b.appOrder) {
                return 1;
              }
              return 0;
            });

            localStorage.setItem('ALL_APPS', JSON.stringify(allApps));

            return this.treatApps();
          });
        });
    }
  }

  treatApps(): Application[] {
    this.userApps = JSON.parse(localStorage.getItem('ALL_APPS'));
    if (this.userApps) {
      this.userApps = this.userApps.filter((a) => a.assigned);
    }
    return this.userApps;
  }

  getCountApp(): number {
    this.getOthersApp();
    if (this.othersApps) {
      return this.othersApps.length;
    }
    return 0;
  }

  getOthersApp() {
    this.othersApps = JSON.parse(localStorage.getItem('ALL_APPS'));
    if (this.othersApps) {
      this.othersApps = this.othersApps.filter(
        (a) => !a.assigned && a.code !== 'REPORTING'
      );
    }

    return this.othersApps;
  }

  public navigateToApp(code) {
    this.userService.log(code).subscribe((app) => {});
    let url = this.router.createUrlTree(['/toapp/' + code]);
    if (code === 'REPORTING') {
      url = this.router.createUrlTree(['/reporting']);
    }
    if (code === 'PROVISION') {
      url = this.router.createUrlTree(['/provision']);
    }
    if (code === 'EXTERNAL_TRANSFER' || code === 'OTHER_CHANEL') {
      url = this.router.createUrlTree(['/external-transfer']);
    }
    if (code === 'DIRECT_DEBIT') {
      url = this.router.createUrlTree(['/direct-debit']);
    }
    window.open(url.toString(), '_blank');
  }

  public getIcon(code): string {
    switch (code) {
      case 'SOGETRADE': {
        return './assets/images/services/ic_ops.svg';
      }
      case 'REPORTING': {
        return './assets/images/services/ic_reporting.svg';
      }
      case 'SOGECASHNET': {
        return './assets/images/services/ic_cash.svg';
      }
      case 'PROVISION': {
        return './assets/images/services/ic_mise_dispo.svg';
      }
      case 'EXTERNAL_TRANSFER': {
        return './assets/images/services/ic_other_canals.svg';
      }
      case 'DIRECT_DEBIT': {
        return './assets/images/services/ic_other_canals.svg';
      }
      default: {
        return '';
      }
    }
  }

  public showDetails(): void {
    this.showNewsDetails = true;
  }

  public cancel(): void {
    this.showNewsDetails = false;
  }

  confirmSelection() {
    this.userService.acceptCGU().subscribe(() => {
      this.cguAccepted = true;
    });
  }

  logout() {
    localStorage.clear();
    this.keycloakService.logout();
  }
  navigateToService(service: Services) {
    this.msg = null;
    if (service.code === 'SOFT_TOKEN') {
      this.softTokenActivationService
        .getSoftToken()
        .subscribe((hasoftTokens) => {
          if (hasoftTokens != null && hasoftTokens.toString() === 'false') {
            let url = this.router.createUrlTree([service.link]);
            window.open(url.toString(), '_blank');
          } else {
            this.msg = 'Vous disposez déjà du service Soft Token';
            setTimeout(() => {
              this.msg = null;
            }, 4000);
          }
        });
    }
  }
}
