import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../service/user.service';
import { Roles } from '../../../utils/roles';
import { ClientService } from '../../../service/client.service';
import { Client } from '../../../models/client';

@Component({
  selector: 'app-transfer-dashboard',
  templateUrl: './transfer-dashboard.component.html',
  styleUrls: ['./transfer-dashboard.component.scss'],
})
export class TransferDashboardComponent implements OnInit {
  showSignedTab = false;
  showToSignTab = false;
  tabActive;
  isDataLoaded = false;
  client: Client;

  constructor(
    private userService: UserService,
    private clientService: ClientService
  ) {
    this.tabActive = 'TO-SIGN';
  }

  async ngOnInit() {
    let customerNumber =
      await this.clientService.getCustomerNumberFromLocalStorage();
    this.clientService
      .getClients()
      .toPromise()
      .then((result) => {
        result.forEach((client) => {
          if (client.customerNumber == customerNumber) {
            this.getCurrentUserRoles(client.id);
            this.client = client;
            this.isDataLoaded = true;
          }
        });
      });
  }

  getCurrentUserRoles(clientId): void {
    this.userService
      .getUserRoles(clientId, localStorage.getItem('username'))
      .toPromise()
      .then((result) => {
        this.showToSignTab =
          Roles.hasRole(result, Roles.OTHER_CHANEL_CONSULTANT) ||
          Roles.hasRole(result, Roles.OTHER_CHANEL_VALIDATOR);
        this.showSignedTab =
          Roles.hasRole(result, Roles.OTHER_CHANEL_CONSULTANT) ||
          Roles.hasRole(result, Roles.OTHER_CHANEL_VALIDATOR);
      });
  }

  selectTab(tabLabel: string): void {
    this.tabActive = tabLabel;
  }

  isActive(tabLabel: string): boolean {
    return (
      this.tabActive === tabLabel &&
      this.client !== undefined &&
      this.client != null
    );
  }
}
