import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { attachPatchData } from '@angular/core/src/render3/context_discovery';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: '[app-app-popup]',
  templateUrl: './app-popup.component.html',
  styleUrls: ['./app-popup.component.scss'],
})
export class AppPopupComponent implements OnInit {
  public apps;
  public myInterval;
  constructor(private router: Router, private userService: UserService) {}

  ngOnInit() {
    //await this.userService.setInfoUser();
    this.apps = this.getApps();
    if (!this.apps)
      this.myInterval = setInterval(() => {
        this.apps = this.getApps();
        if (this.apps) {
          clearInterval(this.myInterval);
        }
      }, 100);
  }
  public getIcon(code): string {
    switch (code) {
      case 'SOGETRADE': {
        return './assets/images/apps/app-icon.png';
      }
      case 'SOGECASHNET': {
        return './assets/images/apps/cashnet.png';
      }
      case 'REPORTING': {
        return './assets/images/apps/webged.png';
      }
      case 'WebAffacturage': {
        return './assets/images/apps/facture.png';
      }
      default: {
        return '';
      }
    }
  }

  public navigateToApp(code) {
    let url = this.router.createUrlTree(['/toapp/' + code]);
    if (code == 'REPORTING') {
      url = this.router.createUrlTree(['/reporting']);
    }
    if (code == 'EXTERNAL_TRANSFER' || code == 'OTHER_CHANEL') {
      url = this.router.createUrlTree(['/external-transfer']);
    }
    if (code == 'PROVISION') {
      url = this.router.createUrlTree(['/provision']);
    }
    if (code == 'DIRECT_DEBIT') {
      url = this.router.createUrlTree(['/direct-debit']);
    }
    window.open(url.toString(), '_blank');
  }

  public getApps(): string[] {
    let apps = JSON.parse(localStorage.getItem('ALL_APPS'));
    if (apps) {
      apps = apps.filter((a) => a.assigned);
    }
    return apps;
  }
}
