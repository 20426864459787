import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CafExpert } from '../models/CafExpert';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class CafExpertService {
  constructor(private http: HttpClient) {}

  public findCafExpert(type:string): Observable<CafExpert[]> {
    return this.http.get<CafExpert[]>(
      `${environment.apiUrl}/caf-expert?type=${type}`,
      httpOptions
    );
  }

  
}
