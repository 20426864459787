import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Client } from '../models/client';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SearchNoticeCriteria } from '../models/search-notice-criteria';
import { SearchNcrmCriteria } from '../models/search-ncrm-criteria';

import { environment } from '../../environments/environment';
import { OverdraftAuthorizationDto } from '../modules/dashboard/models/OverdraftAuthorizationDto';
import { OverdraftAuthorizationDetailsDto } from '../modules/dashboard/models/OverdraftAuthorizationDetailsDto';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};
@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(private http: HttpClient) {}
  public client: Client;

  public getClients(): Observable<Client[]> {
    return this.http.get<Client[]>(
      `${environment.apiUrl}/users/clients`,
      httpOptions
    );
  }

  public getAccountsByClient(
    customerNumber: string,
    docType: string
  ): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.apiUrl}/users/clients/${customerNumber}/accounts?docType=${docType}`,
      httpOptions
    );
  }

  public getTypeDocuments(customerNumber: string): Observable<any[]> {
    return this.http.get<string[]>(
      `${environment.apiUrl}/ged/document-types/${customerNumber}`,
      httpOptions
    );
  }

  public findDocumentsbyCriteria(
    customerNumber: string,
    searchNoticeCriteria: SearchNoticeCriteria
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/ged/search-document/${customerNumber}`,
      searchNoticeCriteria,
      httpOptions
    );
  }

  public findImagesbyCriteria(
    searchNcrmCriteria: SearchNcrmCriteria
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/ged/ncrm/get-document`,
      searchNcrmCriteria,
      httpOptions
    );
  }

  public getOverdraftsAuthorize(
    customerNumber: string
  ): Observable<Array<OverdraftAuthorizationDto>> {
    return this.http.get<Array<OverdraftAuthorizationDto>>(
      `${environment.apiUrl}/clients/` +
        customerNumber +
        `/overdraft-authorizations`,
      httpOptions
    );
  }

  public getOverdraftAuthorizeDetails(
    accountNumber: string,
    branchCode: string,
    currencyCode: string
  ): Observable<OverdraftAuthorizationDetailsDto> {
    const params = new HttpParams()
      .set('accountNumber', accountNumber)
      .set('branchCode', branchCode)
      .set('currencyCode', currencyCode);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: params,
    };
    return this.http.get<OverdraftAuthorizationDetailsDto>(
      `${environment.apiUrl}/accounts/overdraft-authorization-details`,
      httpOptions
    );
  }

  public getCustomerClient(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/clients/selected-customer-client`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        params: new HttpParams().set(
          'username',
          localStorage.getItem('username')
            ? localStorage.getItem('username').toLowerCase()
            : null
        ),
      }
    );
  }

  public storeCustomerClient(customerNumber: string): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/clients/store-customer-client`,
      {
        username: localStorage.getItem('username')
          ? localStorage.getItem('username').toLowerCase()
          : null,
        customerNumber: customerNumber,
      },
      httpOptions
    );
  }

  public async getCustomerNumberFromLocalStorage():Promise<string | null> {
    //changing implementation to get CustomerNumber from the server rather than th local storage to avoid cash problem
    let customerNumberCache;
    let obj = await this.getCustomerClient().toPromise();
    if (obj && obj.customerNumber) {
      if (obj.customerNumber.includes('customerNumber')) {
        customerNumberCache = JSON.parse(obj.customerNumber).customerNumber;
      } else {
        customerNumberCache = obj.customerNumber;
      }
    }
    return customerNumberCache;
}
}
