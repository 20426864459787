import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserConfig } from '../models/userConfig';

import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};
@Injectable({
  providedIn: 'root',
})
export class UserClientConfigService {

  constructor(private http: HttpClient) {}
  public balanceSheetCheckPopUp(customerNumber:string,hasPreference:boolean): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.apiUrl}/user-client-config/balance-sheet/check-popup?customerNumber=${customerNumber}&hasPreference=${hasPreference}`,
      httpOptions
    );
  }
  
  public hidePopup(customerNumber: string,flag :string): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/user-client-config/balance-sheet/hide-popup?customerNumber=${customerNumber}&flag=${flag}`, httpOptions);
  }

}
