<div class="close-container">
  <button
    type="button"
    class="btn btn-close"
    aria-label="Close"
    (click)="onNoClick()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-header">
  <img [src]="image" alt="Soft Token" />
</div>
<div class="modal-body">
  <h4>
    {{ headerText }}
  </h4>

  <p [innerHTML]="messageText"></p>
</div>

<div class="modal-footer">
  <div class="cta-container">
    <button
      (click)="redirect()"
      type="button"
      class="btn btn-danger btn-arrow-right"
    >
      {{ buttonText }}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="12"
        fill="none"
      >
        <path
          stroke="#EE5B6F"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M1 4.5h9M6.256 8.244 10 4.5M6.256.758 10 4.502"
        />
      </svg>
    </button>
  </div>
  <form [formGroup]="form">
    <div class="check-container">
      <input type="checkbox" formControlName="hideDialog" />
      <span>Ne plus afficher cette annonce</span>
    </div>
  </form>
</div>
