export enum Features {
  EXTERNAL_TRANSFER = 'EXTERNAL_TRANSFER',
  SOGECASHNET = 'SOGECASHNET',
  SOGETRADE = 'SOGETRADE',
  PROVISION = 'PROVISION',
  VASCO_FEATURE = 'VASCO_FEATURE',
  ACCOUNTS = 'ACCOUNTS',
  COTATIONS = 'COTATIONS',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  AUTHORIZATION_LINE = 'AUTHORIZATION_LINE',
}
