import { Component, OnInit } from '@angular/core';
import { Page } from '../../../../models/page';
import { ProvisionService } from '../../../../service/provision.service';
import { ProvisionDtoList } from '../../../../models/ProvisionDtoList';
import { Client } from 'src/app/models/client';
import { ClientSharedService } from 'src/app/service/client-shared.service';

@Component({
  selector: 'app-provision-signed',
  templateUrl: './provision-signed.component.html',
  styleUrls: ['./provision-signed.component.scss'],
})
export class ProvisionSignedComponent implements OnInit {
  public provisions: ProvisionDtoList;
  public technicalError: boolean;
  page = new Page();
  public client: Client;

  constructor(
    private provisionService: ProvisionService,
    private clientSharedService: ClientSharedService
  ) {
    this.page.init();
  }

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.provisionService
      .findAllSignedMad(354, this.page.currentPage)
      .toPromise()
      .then((result) => {
        this.provisions = result;
        this.page.totalPages = this.provisions.totalPages;
        this.page.totalElements = this.provisions.totalElements;
      });
  }

  getProvisionsCurrentPage() {
    this.provisionService
      .findAllSignedMad(354, this.page.currentPage)
      .toPromise()
      .then((result) => {
        this.provisions = result;
        this.page.totalPages = this.provisions.totalPages;
        this.page.totalElements = this.provisions.totalElements;
      });
  }
}
