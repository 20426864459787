export class SignatureChallengeAndDresponse {
  public challengeKey: string;
  public dResponse: string;


  constructor(challengeKey: string, dResponse: string) {
    this.challengeKey = challengeKey;
    this.dResponse = dResponse;
  }
}
