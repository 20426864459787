<div class="alert">
  <div style="text-align: right;">
<button type="button" class="btn btn-close" aria-label="Close" (click)="closeAlert()">
  <span aria-hidden="true">&times;</span>
</button>
</div>
<div>
      <table>
        <tr>
        <td>  <label >N° Ticket </label><br /> 
          <input    [(ngModel)]="numTicket" disabled="true" >   
          <br />  <br />  <label >Sujet</label><br /> 
            <input    [(ngModel)]="subject" disabled="true" >   </td>
        </tr>
        <tr>
          <td> <label >Message</label><br />    <textarea  style="resize:none"    rows="5"
            cols="100"  [(ngModel)]="message"  disabled="true"></textarea> </td>
          </tr>
</table>
</div>
</div>
