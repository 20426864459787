import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {SoftTokenClientsService} from 'src/app/service/soft-token-clients.service';
import {SoftTokenActivationService} from 'src/app/service/soft-token-activation.service';
import { Router } from '@angular/router';
import { ClientService } from 'src/app/service/client.service';
import { SoftClientList } from 'src/app/models/soft-client-list';


@Injectable({
  providedIn: 'root'
})
export class SoftTokenGuard implements  CanActivate{
  softClientList:SoftClientList;
  constructor(
    private SoftTokenClientsService:SoftTokenClientsService,
    private clientService: ClientService,
    private softTokenActivationService:SoftTokenActivationService,
    private router: Router
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return new Promise((resolve, reject) => {

      this.clientService.getClients()
      .subscribe((res) => {
      let  customersNumber  =  res.map(result => result.customerNumber);
      this.softClientList=new SoftClientList();
      this.softClientList.customerNumbers=customersNumber;

      this.SoftTokenClientsService.checkSoftTokenClients(this.softClientList).subscribe(soft => {
        if(soft){

      this.softTokenActivationService.getSoftToken().subscribe(
        hastoken => {
          if (hastoken != null &&
            hastoken.toString() === 'false'){
              resolve(true);
            }
            else {
              this.router.navigate(["/"]);
              reject(false);
            }
        }
      , (error)=> {
        this.router.navigate(["/"]);
        reject(false);
      });
      }});

  });

    });
  }

}
