import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() page: {
    currentPage: number;
    totalPages: number;
    totalElements: number;
  };
  @Output() getPage = new EventEmitter<void>();
  constructor() {}
  onNext() {
    if (this.page.currentPage < this.page.totalPages - 1) {
      this.page.currentPage += 1;
      return this.getPage.emit();
    }
  }
  onPrevious() {
    if (this.page.currentPage > 0) {
      this.page.currentPage -= 1;
      return this.getPage.emit();
    }
  }
  ngOnInit() {}

}
