<!-------------------------------- View File --------------------------------------->
<div class="pdf-viewer-popup"></div>
<div class="pdf-viewer-popup-container">
  <div class="pdf-viewer-popup-header">
    <div class="close-container">
      <button type="button" class="btn btn-close" aria-label="Close" (click)="closeFileViewer.emit()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <!-- <div>
      <div *ngIf="pdfReference" class="pdf-viwer-info">
        <span class="label">Nom du fichier | </span>
        <span class="info">{{ pdfReference }}</span>
      </div>
    </div> -->
  </div>
  <div class="pdf-viewer-popup-file">
    <ngx-doc-viewer
      [url]="pdfSrc"
      [viewer]="viewer"
      style="width: 100%;height: 100%;"
    ></ngx-doc-viewer>
  </div>
</div>
<!----------------------------------------------------------------------------------->
