import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Page } from '../../../models/page';
import { SignatureChallengeAndDresponse } from '../../../models/signatureChallengeAndDresponse';
import { UserRolesDTO } from '../../../models/UserRolesDto';
import { ExternalTransferService } from '../../../service/external-transfer.service';
import { UserService } from '../../../service/user.service';
import { DialogSignComponent } from '../../shared-module/dialog-sign/dialog-sign.component';
import { SignMessages } from '../../shared-module/dialog-sign/signMessages';
import { Roles } from '../../../utils/roles';
import { GetFileRequestDto } from '../models/getFileRequestDto';
import { GetFileResponseDto } from '../models/getFileResponseDto';
import { ExternalTransferClient } from '../models/externalTransferClient';

@Component({
  selector: 'app-to-sign',
  templateUrl: './to-sign.component.html',
  styleUrls: ['./to-sign.component.scss'],
})
export class ToSignComponent implements OnInit {
  files: Array<GetFileResponseDto>;
  page = new Page();
  fileProcessRequest: GetFileRequestDto = new GetFileRequestDto();
  searchCriteria: string;
  noFilesFound;
  userCanSign;
  signingOrderId = null;
  showSignatureForm = false;
  signMessages: SignMessages;
  @ViewChild(DialogSignComponent) signingDialog: DialogSignComponent;
  @Input() client: ExternalTransferClient;

  constructor(
    private externalTransfer: ExternalTransferService,
    private userService: UserService
  ) {
    this.page.init();
    this.fileProcessRequest.reference = '';
    this.signMessages = new SignMessages('Sign order', null, null);
  }

  ngOnInit(): void {
    this.getCurrentPageTransfers();
    this.userService
      .getUserRoles(this.client.id, localStorage.getItem('username'))
      .toPromise()
      .then((result) => {
        this.userCanSign = this.hasRole(result, Roles.OTHER_CHANEL_VALIDATOR);
      });
  }

  private hasRole(result: UserRolesDTO, role: string): boolean {
    return result.userRoles.indexOf(role) !== -1;
  }

  getCurrentPageTransfers(): void {
    this.externalTransfer
      .getFilesWithInProgressSignature(
        this.client.id,
        this.page.currentPage,
        this.fileProcessRequest
      )
      .toPromise()
      .then((result) => {
        this.files = result.contentList;
        this.page.totalPages = result.totalPages;
        this.page.totalElements = result.totalElements;
        this.noFilesFound =
          result.contentList == null || result.contentList.length <= 0;
      })
      .catch(() => {});
  }

  searchFileProcess(): void {
    this.page.currentPage = 0;
    this.fileProcessRequest.reference = this.searchCriteria;
    this.getCurrentPageTransfers();
  }

  signOrder(data: SignatureChallengeAndDresponse): void {
    this.externalTransfer
      .signTransfer(
        this.client.id,
        this.signingOrderId,
        data.challengeKey,
        data.dResponse
      )
      .toPromise()
      .then((result) => {
        this.signingOrderId = null;
        const signatureCompleted =
          this.files.filter(
            (file) =>
              file.signatureStatus === 'COMPLETED' &&
              file.id === this.signingOrderId
          ).length !== 0;
        this.signMessages.signatureSuccessMessage = signatureCompleted
          ? "L'ordre a bien été signé, vous pouvez le consulter dans le menu HISTORIQUE DES ORDRES"
          : 'Votre signature a été prise en compte';
        this.getCurrentPageTransfers();
      })
      .catch((errorMessage) => {
        this.signMessages.signatureErrorMessage = errorMessage.error.message;
      });
  }

  cancelValidation(): void {
    this.signingOrderId = null;
    this.showSignatureForm = false;
    this.signMessages.signatureErrorMessage = null;
  }

  startSigningOrder(id): void {
    this.signingOrderId = id;
    this.showSignatureForm = true;
    this.signMessages = new SignMessages('Sign order', null, null);
    this.signingDialog.signForm.reset();
  }
}
