import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthorizationService } from '../../../../core/auth-utils/services/authorization.service';
import { UserService } from '../../../../service/user.service';
import { Roles } from '../../../../utils/roles';
import { UserRolesDTO } from '../../../../models/UserRolesDto';
import { ProvisionService } from '../../../../service/provision.service';
import { ProvisionDtoList } from '../../../../models/ProvisionDtoList';
import { Page } from '../../../../models/page';
import { ClientSharedService } from 'src/app/service/client-shared.service';
import { AlertComponent } from 'ngx-bootstrap';
import { DialogSignComponent } from '../../dialog-sign/dialog-sign.component';
import { Client } from 'src/app/models/client';
import { ProvisionDto } from 'src/app/models/provisionDto';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-provision-dashboard',
  templateUrl: './provision-dashboard.component.html',
  styleUrls: ['./provision-dashboard.component.scss'],
})
export class ProvisionDashboardComponent implements OnInit {
  toggle = [true, true, true];
  public showToSignMenu: boolean;
  public showMyBeneficiary: boolean;
  public showAddBeneficiary: boolean;
  public showProvisionForm = 'hideModal';
  public provisions: ProvisionDtoList;
  public client: Client;
  public isProvisionToSign: boolean = false;
  public provisionId: number;
  page = new Page();
  message = `Quelle est votre décision concernant cette opération suite à l'acceptation de votre initiateur ?`;
  signMessage =
    'Vous êtes sur le point de signer cette opération. Entrer la code de signature.';
  VALIDATE = 'validate';
  EMPTY = '';
  actionButtonClicked: { button: string } = { button: this.EMPTY };
  snackbarMessage = "L'opération a été rejetée";
  rejectProviMessage = "L'opération a été rejetée provisoirement";
  validationMessage = "L'opération a bien été signée";
  validationButton = {
    button: null,
  };
  @ViewChild('validateProvisionAlert') validateProvisionyAlert: AlertComponent;
  @ViewChild('app_dialog_provision_sign')
  appDialogPorivisionSign: DialogSignComponent;
  public commentObj: {
    comment: string;
    challengeKey: string;
    dpResponse: string;
  };
  validateMessage =
    'Vous êtes sur le point de valider cette décision. Êtes-vous sûr de vouloir continuer ?';

  constructor(
    private authorizationService: AuthorizationService,
    private userService: UserService,
    private provisionService: ProvisionService,
    private clientSharedService: ClientSharedService
  ) {
    this.page.init();
  }

  ngOnInit() {
    this.commentObj = { comment: null, dpResponse: null, challengeKey: null };
    this.client = this.clientSharedService.getClient();
    this.userService
      .getUserRoles(354, localStorage.getItem('username'))
      .toPromise()
      .then((result) => {
        this.showToSignMenu = this.hasRole(result, Roles.BENEFICIARY_VALIDATOR);
        this.showMyBeneficiary =
          this.hasRole(result, Roles.BENEFICIARY_VALIDATOR) ||
          this.hasRole(result, Roles.BENEFICIARY_INITIATOR);
        this.showAddBeneficiary = this.hasRole(
          result,
          Roles.BENEFICIARY_INITIATOR
        );
      });

    this.provisionService
      .findAllToSignMad(354, this.page.currentPage)
      .toPromise()
      .then((result) => {
        this.provisions = result;
        this.page.totalPages = this.provisions.totalPages;
        this.page.totalElements = this.provisions.totalElements;
      });
  }

  getProvisionsCurrentPage(): void {
    this.provisionService
      .findAllToSignMad(354, this.page.currentPage)
      .toPromise()
      .then((result) => {
        this.provisions = result;
        this.page.totalPages = this.provisions.totalPages;
        this.page.totalElements = this.provisions.totalElements;
      });
  }

  // tslint:disable-next-line:typedef
  private hasRole(result: UserRolesDTO, role: string) {
    return result.userRoles.indexOf(role) !== -1;
  }

  onHide() {
    this.toggle.forEach((element, index) => (this.toggle[index] = true));
  }

  showProvision() {
    this.showProvisionForm = 'showModal';
  }

  closeProvisionForm() {
    this.showProvisionForm = 'hideModal';
  }

  cancelValidation(): void {
    this.isProvisionToSign = false;
  }

  signProvision(provision: ProvisionDto): void {
    this.isProvisionToSign = true;
    this.provisionId = provision.id;
  }

  validate() {
    this.provisionService
      .signProvision(
        354,
        this.provisionId,
        this.commentObj.dpResponse,
        this.commentObj.challengeKey
      )
      .subscribe(
        () => this.terminateAcceptValidation(),
        (error) => this.appDialogPorivisionSign.onRefresh(null)
      );
  }

  terminateAcceptValidation() {
    Swal.fire({
      text: 'Votre mise à disposition est en cours de traitement en agence. Vous pouvez consulter son statut dans le menu SUIVI DES MAD',
      icon: 'warning',
      dangerMode: false,
    } as any),
      setTimeout(function () {
        window.location.reload();
      }, 5000);
  }
}
