import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-refresh-page',
  templateUrl: './refresh-page.component.html',
  styleUrls: ['./refresh-page.component.scss']
})
export class RefreshPageComponent implements OnInit {

  constructor(private router: Router, private activatedroute: ActivatedRoute) { }

  ngOnInit() {

    const pageToRefresh = this.activatedroute.snapshot.params["page"];
    
    this.router.navigate([pageToRefresh]);

  }

}
