import { Injectable } from '@angular/core';
import { Client } from '../models/client';
import { BehaviorSubject } from "rxjs";
@Injectable({
  providedIn: 'root',
})
export class ClientSharedService {  
  constructor() {}

  private _client: Client;

  addClient(client: Client) {
    this._client = client;
  }

  getClient(): Client {
    return this._client;
  }
}
