<div class="transfer-container">
  <h2 class="title-heading">Mes prélèvements</h2>
  <div class="tabs-container">
    <div class="tabs-header">
      <button
        *ngIf="userCanImport"
        class="tab-item"
        (click)="selectTab('INIT')"
        [class.active]="isActive('INIT')"
      >
        <img alt="add-icon" src="../../../../assets/images/add.svg" />
        Initier un prélèvement
      </button>
      <ng-container *ngIf="userCanConsult || userCanSign || userCanDisplayFile">
        <button
          class="tab-item"
          (click)="selectTab('TO-SIGN')"
          [class.active]="isActive('TO-SIGN')"
        >
          <img alt="add-icon" src="../../../../assets/images/suivi.svg" />
          Suivi des prélèvements
        </button>
        <button
          class="tab-item"
          (click)="selectTab('HISTORY')"
          [class.active]="isActive('HISTORY')"
        >
          <img alt="add-icon" src="../../../../assets/images/historique.svg" />
          Historique des prélèvements
        </button>
      </ng-container>
    </div>
    <div class="tabs-content">
      <app-db-init
        (fileValidatedEvent)="selectTab('TO-SIGN')"
        *ngIf="isActive('INIT')"
      ></app-db-init>
      <app-db-to-sign
        *ngIf="isActive('TO-SIGN')"
        [signatureStatus]="toSignTabSignatureStatus"
        [historyMode]="false"
        [userCanConsult]="userCanConsult"
        [userCanSign]="userCanSign"
        [userCanDisplayFile]="userCanDisplayFile"
        (validateCancelEvent)="selectTab('HISTORY')"
      ></app-db-to-sign>
      <app-db-to-sign
        *ngIf="isActive('HISTORY')"
        [signatureStatus]="hisoryTabStatus"
        [historyMode]="true"
        [userCanConsult]="userCanConsult"
        [userCanSign]="userCanSign"
        [userCanDisplayFile]="userCanDisplayFile"
      ></app-db-to-sign>
      <div *ngIf="isActive('ROLES-ERROR')">
        <div>
          <!--<h1>Aucun role affecté</h1>-->
        </div>
      </div>
    </div>
  </div>
</div>
