import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-popin',
  templateUrl: './confirm-popin.component.html',
  styleUrls: ['./confirm-popin.component.scss']
})
export class ConfirmPopinComponent implements OnInit {

  @Input() message: string;
  @Input() openPopinFlag: boolean;
  @Output() popinClosedEvent = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  response(res:boolean){
    this.popinClosedEvent.emit(res);
  }

}
