import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Client } from 'src/app/models/client';
import { UserConfig } from 'src/app/models/userConfig';
import { AccountService } from 'src/app/service/account.service';
import { ClientService } from 'src/app/service/client.service';
import { UserConfigService } from 'src/app/service/user-config-service';
import { UserClientConfigService} from 'src/app/service/user-client-config.service';
import {SoftTokenClientsService} from 'src/app/service/soft-token-clients.service';
import { SoftTokenPopinComponent } from '../../soft-token-module/soft-token-popin/soft-token-popin.component';
import { AccountBalanceDto } from '../models/AccountBalanceDto';
import { OverdraftAuthorizationDto } from '../models/OverdraftAuthorizationDto';
import { FeatureFlippingService } from '../../../service/feature-flipping.service';
import { Features } from '../../../models/features';
import { map } from 'rxjs/operators';
import { DialogCommonComponent } from '../../popin/dialog-common/dialog-common.component';
import { forkJoin } from 'rxjs'
import { SoftClientList } from 'src/app/models/soft-client-list';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  accountList: Array<AccountBalanceDto> = [];
  overDraftsList: Array<OverdraftAuthorizationDto> = [];
  accountsAreLoaded = false;
  overDraftsAreLoaded = false;
  clients: Array<Client>;
  numberMaxOfSoftToken: number;
  userConfig: UserConfig;
  isAccountsEnabled: boolean;
  isCotationEnabled: boolean;
  isAuthorizationLineEnabled: boolean;
  softClientList:SoftClientList;

  constructor(
    public dialog: MatDialog,
    private userConfigService: UserConfigService,
    private userClientConfigService: UserClientConfigService,
    private clientService: ClientService,
    private accountService: AccountService,
    private SoftTokenClientsService:SoftTokenClientsService,
    private featureFlippingService: FeatureFlippingService
  ) {}

  async ngOnInit(): Promise<void> {
    let customerNumber = "";
    await this.clientService.getCustomerClient().pipe(map((obj) => {
      if (obj && obj.customerNumber) {
        if (obj.customerNumber.includes('customerNumber')) {
          customerNumber = JSON.parse(obj.customerNumber).customerNumber;
        } else {
          customerNumber = obj.customerNumber;
        }
      }
    })).toPromise().then(() => {

      this.featureFlippingService
      .getEnabledFeatures()
      .subscribe((resp: String[]) => {
        if (resp != null && resp.length != 0) {
          this.isAccountsEnabled = resp.includes(Features.ACCOUNTS);
          this.isCotationEnabled = resp.includes(Features.COTATIONS);
          this.isAuthorizationLineEnabled = resp.includes(Features.AUTHORIZATION_LINE);
        }

      });



      this.userClientConfigService.balanceSheetCheckPopUp(customerNumber,true).subscribe( value=>  {
         if(value){

          let year=new Date().getFullYear()-1;
         this.dialog.open(DialogCommonComponent, {
          disableClose: true ,
          data: {
            headerText: 'Campagne Collecte des Bilans Fiscaux',
            messageText: "  Nous vous remercions  d'importer  le bilan fiscal "+year+" de votre entreprise <br>"+
            'dans le cadre du renouvellement de vos dossiers de crédits',
            buttonText: 'IMPORTER MON BILAN',
            redirection: '/balance-sheet',
            image:'/assets/images/campagne-bilans -fiscaux.png',            flag: 'BALANCE_SHEET'
          }
        });
         }
         else {
          this.openSoftTokenPopinIfUserDoesntHaveSoftToken();
         }
      });

    });


  }

  openSoftTokenPopinIfUserDoesntHaveSoftToken(): void {
    this.clientService.getClients()
  .subscribe((res) => {
  let  customersNumber  =  res.map(result => result.customerNumber);
  this.softClientList=new SoftClientList();
  this.softClientList.customerNumbers=customersNumber;

  this.SoftTokenClientsService.checkSoftTokenClients( this.softClientList).subscribe(soft => {
    if(soft){
    const hasoftTokens = localStorage.getItem('hasSoftToken');
    this.userConfigService.findMaxOfnumberOfSoftToken().subscribe((result) => {
      this.numberMaxOfSoftToken = Number(result);
    });
    this.userConfigService
      .findUserConfigByUser(localStorage.getItem('username'))
      .subscribe((userConfig) => {
        this.userConfig = userConfig;
        let hideSoftToken = this.userConfig.showSoftTokenPopup;
        if (
          hasoftTokens != null &&
          hasoftTokens === 'false' &&
          hideSoftToken === false &&
          this.userConfig.numberOfSoftTokenPopupDisplay <=
            this.numberMaxOfSoftToken
        ) {
         this.openSoftTokenPopin();
        }
      });
    }});
  });

  }

  openSoftTokenPopin(): void {
    this.userConfigService
      .numberOfSoftToken(localStorage.getItem('username'))
      .subscribe(() => {
        this.dialog.open(SoftTokenPopinComponent, null);
      });
  }
}
