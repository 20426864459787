import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IdentityChallengeDTO } from '../models/identityChallengeDTO';

import { environment } from '../../environments/environment';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class ChallengeService {
  constructor(private http: HttpClient) {}

  public getChallenge(): Observable<IdentityChallengeDTO> {
    return this.http.get<IdentityChallengeDTO>(
      `${environment.apiUrl}/signingschema/challenge`,
      httpOptions
    );
  }
}
