import { IdTypeCode } from 'src/app/models/id-type-code';

export class Beneficiary {
  public firstName: string;
  public lastName: string;
  public phoneNumber: string;
  public identityType: IdTypeCode;
  public identityNumber: string;

  constructor() {}
}
