<div class="body-wrapper">
  <div class="transfer-filter-container">
    <div class="filter-form-container">
      <div class="search-control-container">
        <input type="text" class="input-search" placeholder="Recherche ..." [(ngModel)]="searchCriteria">
        <button type="submit" class="submit-search-btn" (click)="searchFileProcess()">
          <img src="../../../assets/images/search-icon@2x.png" width="19"/>
        </button>
      </div>
    </div>
  </div>

  <br/>
  <div *ngIf="noFilesFound; then noResultFound else filesProcess"></div>
  <ng-template #filesProcess>
    <div class="striped-table" *ngFor="let file of files">
      <app-transfer-widget [transfer]="file"></app-transfer-widget>
    </div>
    <app-pagination [page]="page" (getPage)="getCurrentPageTransfers()"></app-pagination>
  </ng-template>

  <ng-template #noResultFound>
    Vous n'avez aucun ordre signé
    </ng-template>

</div>
