export class Page {
  currentPage: number;
  totalPages: number;
  totalElements: number;

  constructor() {
  }

  init(): void {
    this.currentPage = 0;
    this.totalElements = null;
    this.totalPages = null;
  }
}
