import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from '../shared-module/shared.module';
import {TransferDashboardComponent} from './transfer-dashboard/transfer-dashboard.component';
import {ToSignComponent} from './to-sign/to-sign.component';
import {SignedComponent} from './signed/signed.component';
import {FormsModule} from '@angular/forms';
import {TransferWidgetComponent} from './widgets/transfer-widget/transfer-widget.component';

@NgModule({
  declarations: [TransferDashboardComponent, ToSignComponent, SignedComponent, TransferWidgetComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
  ]
})
export class ExternalTransferModule {
}
