import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-soft-token-new',
  templateUrl: './soft-token-new.component.html',
  styleUrls: ['./soft-token-new.component.scss']
})
export class SoftTokenNewComponent implements OnInit {
  public value: number = 10;

  constructor() {}

  ngOnInit(): void {}

  indicator(type: string) {
    if (this.value < 110 && this.value > 0) {
      if (type === 'increment') {
        this.value += 10;
      }

      if (type === 'decrement') {
        this.value -= 10;
      }
    }
  }
}
