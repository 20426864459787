<div class="transfer-aside-menu"></div>
<div class="transfer-container">
  <div class="transfer-title-container">
    <div class="page-title-content">
      <h2 class="title-heading-model03">
        {{client? client.name : ''}}
      </h2>
      <p>
      </p>
    </div>
  </div>
  <div class="transfer-filter-container">
    <div class="filter-form-container">
      <div class="search-control-container">
      </div>

    </div>
    <div class="tabs-container">
      <button class="tab-item" (click)="selectTab('SIGNED')" [class.active]="isActive('SIGNED')">
        HISTORIQUE DES ORDRES
      </button>
      <button class="tab-item" (click)="selectTab('TO-SIGN')" [class.active]="isActive('TO-SIGN')">
        SIGNATURE DES ORDRES
      </button>
    </div>
  </div>
  <div>
    <app-signed *ngIf="isActive('SIGNED')" [client]="client" ></app-signed>
    <app-to-sign *ngIf="isActive('TO-SIGN')" [client]="client"></app-to-sign>
  </div>
</div>
