import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-message-popin',
  templateUrl: './message-popin.component.html',
  styleUrls: ['./message-popin.component.scss']
})
export class MessagePopinComponent implements OnInit {

  @Input() message: string;
  @Input() success: boolean;
  @Input() openPopinFlag: boolean;
  @Input() mode:string = "";
  @Output() popinClosedEvent= new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  closeTab(){
    this.popinClosedEvent.emit();
  }

}
