import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/core/auth-utils/guards/auth.guard';
import { DbDashboardComponent } from './db-dashboard/db-dashboard.component';

const routes: Routes = [{
  path: '',
  canActivate: [AuthGuard],
  data: {
    roles: ['DIRECT_DEBIT'],
  },
  component: DbDashboardComponent,
  children: [],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DirectDebitRoutingModule { }
