import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UserService} from 'src/app/service/user.service';
import {environment} from '../../../environments/environment';
import {ResponseCronto} from '../../models/response-cronto';

@Component({
  selector: 'app-soft-token',
  templateUrl: './soft-token.component.html',
  styleUrls: ['./soft-token.component.scss'],
})
export class SoftTokenComponent implements OnInit {
  public deviceCodeLengthErrorMessage = '';
  public enableSubmitDeviceCode = false;

  constructor(
    private userService: UserService,
    private activatdRoute: ActivatedRoute,
  ) {}

  stepNumber = -1;
  uuid: string;
  phoneNumber = '';
  userId: string;
  softTokenActivationStatus: string;
  otpCode: string;
  crontoMessage1: string;
  deviseCode: string;
  deviceCodeToSendToApi: string;
  crontoMessage2: string;

  otpButtonDisabled = true;
  firstCrontonButtonDisabled: boolean;
  secondCrontonButtonDisabled: boolean;

  counter: number;
  timeLeft: number;
  interval;
  isError = false;
  errorStatusValidationCronto1 = null;

  urlPortal = environment.urlPortal;

  appStore = environment.appStore;
  googlePlay = environment.googlePlay;

  ngOnInit() {
    this.isError = false;
    this.otpButtonDisabled = true;
    this.firstCrontonButtonDisabled = true;
    this.secondCrontonButtonDisabled = true;

    this.activatdRoute.queryParams.subscribe((params) => {
      this.uuid = params['uuid'];
    });

    this.userService.getPhoneNumber(this.uuid).subscribe(
      (res) => {
        this.phoneNumber = res.phoneNumber;
        this.userId = res.userId;
        this.softTokenActivationStatus = res.status;
        if (this.softTokenActivationStatus === 'ACTIVATED') {
          this.stepNumber = 6;
        }
        this.stepNumber = this.checkStep() ? 1 : 0;
        this.counter = Number(res.sendingInSeconds);
        if (this.checkStep()) {
          this.startTimer();
          this.otpButtonDisabled = false;
          this.firstCrontonButtonDisabled = false;
          localStorage.setItem('numberstep', null);
        }
      },
      (error) => {
        this.isError = true;
      }
    );
  }

  formatInput(): void {
    const cleanedInput = this.deviseCode.toString().replace(/\D/g, '');
    const formattedInput = cleanedInput.match(/.{1,4}/g);

    this.deviseCode = formattedInput ? formattedInput.join(' ') : '';

    if (cleanedInput.length === 16) {
      this.enableSubmitDeviceCode = true;
      this.deviceCodeLengthErrorMessage = '';
      this.deviceCodeToSendToApi = this.deviseCode;
    } else {
      this.enableSubmitDeviceCode = false;
      this.deviceCodeLengthErrorMessage = 'Veuillez saisir les 16 chiffres !';
    }
  }

  isStepEqual(stepNumber): boolean {
    return (
      this.stepNumber === stepNumber &&
      !this.isError &&
      this.softTokenActivationStatus !== 'ACTIVATED'
    );
  }

  checkStep(): boolean {
    return (
      localStorage.getItem('numberstep') != null &&
      localStorage.getItem('numberstep') != undefined
    );
  }

  startTimer() {
    this.timeLeft = this.counter;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  nextStep(): void {
    this.stepNumber++;
    switch (this.stepNumber) {
      case 1: {
        this.sendOTP();
        this.otpButtonDisabled = false;
        break;
      }
      case 2: {
        this.userService.validateOTP(this.userId, this.otpCode).subscribe(
          (res) => {
            // if otp valid => get QR2
            if (res.otpValid) {
              this.userService.stepRegsitrationToken(this.uuid).subscribe(
                (res) => {
                  this.crontoMessage1 = this.getImagePngBase64(res);
                  this.firstCrontonButtonDisabled = false;
                },
                (error) => {
                  this.isError = true;
                  this.stepNumber--;
                }
              );
            } else {
              this.stepNumber--;
            }
          },
          (error) => {
            this.isError = true;
          }
        );
        break;
      }
      case 4: {
        this.deviceCodeToSendToApi = this.deviceCodeToSendToApi.replace(/\s/g, '');
        this.userService
          .stepActivationToken(this.uuid, this.deviceCodeToSendToApi)
          .subscribe(
            (res) => {
              this.crontoMessage2 = this.getImagePngBase64(res);
              this.secondCrontonButtonDisabled = false;
            },
            (error) => {
              if (error.status === 400) {
                this.stepNumber = 3;
                this.errorStatusValidationCronto1 = error.status;
              } else {
                this.isError = true;
              }
            }
          );
        break;
      }
      default: {
        break;
      }
    }
  }

  getImagePngBase64(res: ResponseCronto): string {
    return 'data:' + res.data + ';base64,' + res.base64;
  }

  sendOTP() {
    this.userService.sendOTP(this.userId).subscribe(
      () => {
        this.startTimer();
      },
      () => {
        this.isError = true;
      }
    );
  }

  status: boolean = false;

  clickEvent() {
    this.status = !this.status;
  }

  onClickedOutside(e: Event) {
    this.status = false;
  }

  onInputCronto1Change(): void {
    this.errorStatusValidationCronto1 = 200;
  }
}
