import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class FeatureFlippingService {
  constructor(private http: HttpClient) {}

  public isFeatureEnabled(feature: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.apiUrl}/feature?feature=${feature}`,
      httpOptions
    );
  }

  public getEnabledFeatures(): Observable<String[]> {
    return this.http.get<String[]>(
      `${environment.apiUrl}/feature/features-enabled`,
      httpOptions
    );
  }
}
