import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DirectDebitList } from '../models/directDebitList';
import { environment } from '../../environments/environment';
import { MATERIAL_SANITY_CHECKS } from '@angular/material';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class DirectDebitService {
  downloadSortFile(customerNumber: string, id: string) {
    return this.http.get(
      `${environment.apiUrl}/clients/${customerNumber}/direct-debits/${id}/sort/download`,
      {
        responseType: 'arraybuffer',
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  signDirectDebit(
    customerNumber: string,
    fileId: string,
    challengeKey: string,
    dResponse: string
  ): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/clients/${customerNumber}/direct-debits/${fileId}/signature?challengeKey=${challengeKey}&dpResponse=${dResponse}`,
      httpOptions
    );
  }

  downloadFile(customerNumber: string, name: string) {
    return this.http.get(
      `${environment.apiUrl}/clients/${customerNumber}/direct-debits/download/${name}`,
      {
        responseType: 'arraybuffer',
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  constructor(private http: HttpClient) {}

  cancelDirectDebitFile(id: any, customerNumber): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}/clients/${customerNumber}/direct-debits/${id}/cancel`,
      { headers: httpOptions.headers, responseType: 'text' }
    );
  }

  cancelDirectDebitAfterInitialization(
    id: any,
    customerNumber
  ): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}/clients/${customerNumber}/direct-debits/${id}`,
      { headers: httpOptions.headers, responseType: 'text' }
    );
  }

  validateDirectDebitFile(id: any, customerNumber): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/clients/${customerNumber}/direct-debits/${id}/validation`,
      { headers: httpOptions.headers, responseType: 'text' }
    );
  }

  public getDirectDebitFilesWithInProgressSignature(
    currentPage: number,
    searchCriteria: string,
    body: any
  ): Observable<DirectDebitList> {
    return this.http.post<DirectDebitList>(
      `${environment.apiUrl}/direct-debits?page=${currentPage}&searchCriteria=${searchCriteria}`,
      body,
      httpOptions
    );
  }

  // Returns an observable
  upload(file, customerNumber?): Observable<any> {
    // Create form data
    const formData = new FormData();

    // Store form name as "file" with file data
    formData.append('file', file, file.name);

    // Make http post request over api
    // with formData as req
    return this.http.post(
      `${environment.apiUrl}/clients/${customerNumber}/direct-debits/upload`,
      formData
    );
  }
}
