<div class="apps-list">
  <ng-container *ngFor="let app of userApps; let index = index">
    <ng-container *ngIf="index < 3">
      <div class="app-item">
        <img [src]="getIcon(app?.code)" />
        <div class="app-item-content">
          <h2>{{ app?.name }}</h2>
          <p>{{ app?.description.slice(0, -1) }}</p>
        </div>
        <div class="app-item-link" (click)="navigateToApp(app.code)">
          <span>ACCÉDER</span>
          <img src="assets/images/arrow-go.svg" />
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
