import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DirectDebitService } from 'src/app/service/direct-debit.service';
import { DirectDebitRecap } from 'src/app/models/DirectDebitRecap';
import { ClientService } from 'src/app/service/client.service';

@Component({
  selector: 'app-db-init',
  templateUrl: './db-init.component.html',
  styleUrls: ['./db-init.component.scss'],
})
export class DbInitComponent implements OnInit {
  @Output() fileValidatedEvent = new EventEmitter<any>();
  file: File;
  directDebitRecap: DirectDebitRecap;
  showValidationRecap: boolean = false;
  openPopinMessageFlag: boolean = false;
  openPopinMessageSuccess = 'Opération terminée avec succès';
  openPopinMessageError =
    'Le service est indisponible,  merci de réessayer ultérieurement';
  openPopinMessage = '';
  popinMode = '';
  loaderUploadMessage = 'Merci de patienter';
  loaderMode = false;
  uploadDefaultMessage =
    'Glissez et déposez votre fichier ou cliquez sur le bouton';
  uploadMessage = this.uploadDefaultMessage;
  today = new Date();
  openPopinConfirmFlag = false;
  uploadMessageError = false;
  validateDirectDebitLoader = false;
  fileSrc: string;
  fileReference: string;
  showFileViewer: boolean;
  openPopinSuccessFlag: boolean;

  constructor(
    private directDebitService: DirectDebitService,
    private clientService: ClientService
  ) {}

  ngOnInit() {}

  _onSelect(event) {
    //this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    //this.files.splice(this.files.indexOf(event), 1);
  }

  async onSelect(event): Promise<void> {
    this.uploadMessageError = false;
    if (event.addedFiles.length > 0) {
      this.file = event.addedFiles[0];
      this.loaderMode = true;
      this.uploadMessage = this.loaderUploadMessage;

      this.directDebitService
        .upload(
          this.file,
          await this.clientService.getCustomerNumberFromLocalStorage()
        )
        .toPromise()
        .then((result) => {
          this.directDebitRecap = result;
          this.showValidationRecap = true;
        })
        .catch((error) => {
          this.openPopinMessageFlag = true;
          if (error.status == 400) {
            this.openPopinMessage = error.error.message;
          } else {
            this.openPopinMessage = this.openPopinMessageError;
          }
        })
        .finally(() => {
          this.loaderMode = false;
          this.uploadMessage = this.uploadDefaultMessage;
          this.file = null;
        });
    }

    if (event.rejectedFiles.length > 0) {
      this.uploadMessageError = true;
    }
  }

  async validateDirectDebit(id) {
    this.validateDirectDebitLoader = true;
    this.directDebitService
      .validateDirectDebitFile(
        id,
        await this.clientService.getCustomerNumberFromLocalStorage()
      )
      .toPromise()
      .then(() => {
        this.openPopinSuccessFlag = true;
      })
      .catch((error) => {
        this.openPopinMessageFlag = true;
        if (error.status == 400) {
          this.openPopinMessage = error.error.message;
        } else {
          this.openPopinMessage = this.openPopinMessageError;
        }
      })
      .finally(() => {
        this.validateDirectDebitLoader = false;
      });
  }

  async cancelDirectDebit(id) {
    this.directDebitService
      .cancelDirectDebitFile(
        id,
        await this.clientService.getCustomerNumberFromLocalStorage()
      )
      .toPromise()
      .then(() => {
        this.directDebitRecap = null;
        this.showValidationRecap = false;
        this.loaderMode = false;
        this.uploadMessage = this.uploadDefaultMessage;
        this.file = null;
      })
      .catch((error) => {
        //this.openPopinMessageFlag = true;
        this.closePopin();
        if (error.status == 400) {
          this.openPopinMessage = error.error.message;
        } else {
          this.openPopinMessage = this.openPopinMessageError;
        }
      });
  }

  closePopin() {
    this.openPopinMessageFlag = false;
    this.showValidationRecap = false;
    this.loaderMode = false;
    this.uploadMessage = this.uploadDefaultMessage;
    this.file = null;
    this.directDebitRecap = null;
  }

  closeConfirmPopin(res: boolean) {
    this.uploadMessageError = false;
    this.openPopinConfirmFlag = false;
    if (res) {
      this.cancelDirectDebit(this.directDebitRecap.id);
    }
  }

  async viewFile(ref: string) {
    this.directDebitService
      .downloadFile(
        await this.clientService.getCustomerNumberFromLocalStorage(),
        ref
      )
      .subscribe((response) => {
        let blob = new Blob([response]);
        let url = window.URL.createObjectURL(blob);
        this.fileSrc = url;
        console.log(this.fileSrc);
        this.fileReference = ref;
        this.showFileViewer = true;
      });
  }

  closeSuccessPopin() {
    this.fileValidatedEvent.emit();
  }
}
